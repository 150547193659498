import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { IUsuarioModel } from '~/types/usuario';
import webStorage from '../../common/webStorage';
import { login } from '../../services/auth';
import { decodedToken } from '~/utils/methods';
import PermissionAdmin from '~/permissions/admin';
import { RootState } from '..';


export interface AuthState {
  usuario: IUsuarioModel
  token: string
  authAssinei:string
}

const state: AuthState = {
  usuario: webStorage.getItem<IUsuarioModel>('usuario') || {},
  token: webStorage.getItem<string>('token') || '',
  authAssinei: webStorage.getItem<string>('auth_token_assinei') ||''
};

const getters: GetterTree<AuthState, RootState> = {
  usuario: (state) => state.usuario,
  accessToken: (state) => state.token,
  accessAdmin: (state) => PermissionAdmin(state.usuario).usuarioAdm,
  accessEmpresaAdmin: (state) => PermissionAdmin(state.usuario).accessEmpresaAdm,
  loginToken: (state) => isTokenExpired(state.token),
  integradoAssinei:(state) => state.usuario.integracaoAssinei
};

const mutations: MutationTree<AuthState> = {
  setUsuario(state, usuario: IUsuarioModel) {
    state.usuario = usuario;
    webStorage.setItem('usuario', usuario);
  },
  setToken(state, token: string) {
    state.token = token;
    if (token) {
      webStorage.removeItem('token');
      webStorage.setItem('token', token);
    } else {
      webStorage.removeItem('token');
    }
  },  
  setAuthtokenAssinei(state , authAssinei:string | null){
    state.authAssinei = authAssinei ?? ''
    webStorage.setItem('auth_token_assinei', authAssinei ?? '');
  }
};


const actions: ActionTree<AuthState, RootState> = {
  async authenticateUser({ commit }, userCredentials) {
    const { email, password } = userCredentials;

    try {
      const usuario = (await login({ email, password }));
      if (usuario.token) {
        commit('setUsuario', usuario);
        commit('setToken', usuario.token);
        commit('setAuthtokenAssinei', usuario.authTokenAssinei);
        return true;
      }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      const objectoErro = err.login_failure || [];
      const mensagem = objectoErro.find((a) => a) || 'Verifique se seu usuário e senha foi preenchido corretamente.';

      throw mensagem;
    }

    return false;
  },
  revokeToken({ commit }) {
    commit('setUsuario', null);
    commit('setToken', null);
    commit('setAuthtokenAssinei', null);
  },
};

const isTokenExpired = (token: string) => {
  if (!token) return false;
  const decoded = decodedToken(token);
  const expirationTimestamp = decoded.exp * 1000;
  const currentTimestamp = Date.now();

  return currentTimestamp < expirationTimestamp;
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions 
};
