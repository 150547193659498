import { render, staticRenderFns } from "./Favorecidos.vue?vue&type=template&id=e9cb397e&scoped=true"
import script from "./Favorecidos.vue?vue&type=script&lang=js"
export * from "./Favorecidos.vue?vue&type=script&lang=js"
import style0 from "./Favorecidos.vue?vue&type=style&index=0&id=e9cb397e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9cb397e",
  null
  
)

export default component.exports