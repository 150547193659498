import { isArray } from 'lodash';

export const validarData = (
  data: string | Date | Array<Date> | Array<string>,
  dias = 0
): { isValid: boolean; proximaDataDisponivel: Date } => {
  if (isArray(data)) {
    const resultados = data.map(a => validarData(a, dias));
    return {
      isValid: resultados.some(resultado => resultado.isValid),
      proximaDataDisponivel: resultados[0].proximaDataDisponivel,
    };
  }

  const dataInformada = new Date(data);
  const dataComparacao = new Date();
  dataComparacao.setDate(dataComparacao.getDate() + dias);
  dataInformada.setHours(0, 0, 0, 0);
  dataComparacao.setHours(0, 0, 0, 0);

  return {
    isValid: dataInformada.getTime() >= dataComparacao.getTime(),
    proximaDataDisponivel: dataComparacao,
  };
};
