import axios from 'axios';
import { IProdutoEmpresaModel, IProdutoModel } from '~/models/produto';
import { IApiOKResult } from '~/types/api';
import BaseService from '../baseServise';


class ServiceProdutoEmpresa extends BaseService<IProdutoModel>{
   constructor() {
    super('ProdutoEmpresa');
  }

  async listProdutoEmpresa (): Promise<IApiOKResult<Array<IProdutoEmpresaModel>>> { 
    return axios.get(`${this.baseURL}/list-produto-empresa`).then(({data}) => data);
  }

}

export default new ServiceProdutoEmpresa();