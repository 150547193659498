import axios from 'axios';
import { LocalParametrizacao } from '~/Enums/aprovacaoParametrizacao';
import { IParametrizacaoEmpresaModel } from '~/models/parametrizacaoEmpresa';
import { IApiOKResult } from '~/types/api';
import BaseService from '../baseServise';

class ServiceParametrizacaoEmpresa extends BaseService<IParametrizacaoEmpresaModel> {
  
  constructor() {
    super('ParametrizacaoEmpresa');
  }

  override async save (model:IParametrizacaoEmpresaModel) {
    return axios.post<IApiOKResult<IParametrizacaoEmpresaModel>>(`${this.baseURL}`, model).then(({data}) => data);
  }

  async parametrizacaoVigente (params: { tipoParametrizacao: LocalParametrizacao }) {
    return axios.get<IApiOKResult<IParametrizacaoEmpresaModel>>(`${this.baseURL}/vigente`, { params }).then(({data}) => data);
  }

  async historico (params: { tipoParametrizacao: LocalParametrizacao }) {
    return axios.get<IApiOKResult<IParametrizacaoEmpresaModel[]>>(`${this.baseURL}/historico`, { params }).then(({data}) => data);
  }

}

export default new ServiceParametrizacaoEmpresa();