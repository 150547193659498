import Auth from '~/layouts/auth.vue';
import Blank from '~/layouts/blank.vue';

export default  {
  path: '/',
  component: Blank,
  children: [
    {
      path: '',
      redirect: '/auth/login', 
    },
    {
      path: '/auth',  
      component: Auth,
      children: [
        {
          path: '',
          redirect: '/auth/login', 
        },
        {
          path: '/auth/login',
          name: 'Login',
          component: ()=> import('./login.vue'),
        },
        {
          path: '/auth/recovery',
          name: 'Recovery',
          component: ()=> import('./recuperar-senha.vue'),
        },
      ]
    },
  ],
};
