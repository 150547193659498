import Vue, { PluginObject } from 'vue';
import CprModalFeeback from '@/components/cpr-modal/cpr-modal-feedback.vue';
import { IOptionsAlert } from '~/types/optionsAlert';

const CprModal: PluginObject<IOptionsAlert> = {
  install: (Vue: Vue.VueConstructor) => {
    Vue.prototype.$cprAlert = (options: IOptionsAlert) => {
      const ModalComponent = Vue.extend(CprModalFeeback);
      const instance = new ModalComponent({
        propsData: options
      });
      
      instance.$mount();
      document.body.appendChild(instance.$el);

      setTimeout(() => {
        Vue.nextTick(() => {
          instance.$props.showModal = true
        });
      }, 50);

      // Função para fechar o modal e remover do DOM
      const closeAndDestroyModal = () => {
        instance.$props.showModal = false;
        // Espera a transição de fechamento do modal antes de destruí-lo
        setTimeout(() => {
          instance.$destroy();
          instance.$el.remove();
        }, 300);
      };

      instance.$props.closeModal = closeAndDestroyModal;
    };
  }
};

Vue.use(CprModal);
