import Blank from '~/layouts/blank.vue';

export default {
  path: 'configuracoes',
  component: Blank,
  children: [
    {
      path: '',
      redirect: 'aprovacao-minuta', 
    },
    {
      path: 'aprovacao-minuta',
      component: () => import('~/views/admin/configuracoes/aprovacao-minuta.vue'),
      name: 'AprovacaoMinuta',
      meta: {
        requiresAuth: true,
        routeAdmin: true,
        breadcrumb: 'Aprovação de Minutas',
        headerComponent: 'HeaderConfiguracoes'
      },
    },
    {
      path: 'assinei',
      component: () => import('~/views/admin/configuracoes/conta-assinei.vue'),
      name: 'ContaAssinei',
      meta: {
        requiresAuth: true,
        routeAdmin: true,
        breadcrumb: 'Integrações',
        headerComponent: 'HeaderConfiguracoes'
      },
    },
  ],
};
