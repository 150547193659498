export enum AprovacaoParametrizacao {
  COM_APROVACAO = 1,
  SEM_APROVACAO,
  OPCIONAL_POR_TITULO
}

export enum LocalParametrizacao {
  MINUTA = 0,
  ENDOSSO,
  TODAS
}

export enum TipoProcessoAprovacao {
  UNIFICADO = 0,
  SEPARADO
}

export const DictionaryStatusAprovacao = {
  [AprovacaoParametrizacao.COM_APROVACAO]: 'Aprovação obrigatória',
  [AprovacaoParametrizacao.SEM_APROVACAO]: 'Não realizar aprovação',
  [AprovacaoParametrizacao.OPCIONAL_POR_TITULO]: 'Aprovação opcional por Título'
}

export const DictionaryTipoProcesso:Record<TipoProcessoAprovacao, string> = {
  [TipoProcessoAprovacao.UNIFICADO]: 'Em um único processo',
  [TipoProcessoAprovacao.SEPARADO]: 'Em processo separado',
}

export const DictionaryLocalParametrizacao:Record<LocalParametrizacao, string> = {
  [LocalParametrizacao.MINUTA]: 'Título',
  [LocalParametrizacao.ENDOSSO]: 'Endosso',
  [LocalParametrizacao.TODAS]: 'Todas',
}
