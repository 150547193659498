
import { ILocalEntregaModel } from '~/models/locais';
import baseService from '../baseServise';

class ServiceLocalEntrega extends baseService<ILocalEntregaModel> {
  constructor() {
    super('LocalEntrega');
  }

}

export default new ServiceLocalEntrega();