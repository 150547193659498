import { ActionTree, GetterTree, MutationTree } from 'vuex';
import webStorage from '~/common/webStorage';
import ServiceDocRegistration from '~/services/DocRegistrationHub';
import type { IUsuarioModel } from '~/types/usuario';
import type { IContaB3, IQueryContaB3 } from '~/models/docRegistrationHub';
import type { RootState } from '..';

/** @todo Apenas abstraindo o código, entretanto é necessário refatorar lógica */
export interface ContaState {
  contasB3: IQueryContaB3
}

const contaB3Default = <IContaB3>{
  prefixo: '',
  miolo: '',
  digito: '',
  stringContaB3: ''
}

const contasB3:IQueryContaB3 = {
  conta00: {...contaB3Default },
  conta10: {...contaB3Default },
  conta40: {...contaB3Default }
}

const state: ContaState = {
  contasB3: webStorage.getItem<IQueryContaB3>('contasB3') || contasB3,
};

const getters: GetterTree<ContaState, RootState> = {
  obterContasB3: (state) => state.contasB3,
};

const mutations: MutationTree<ContaState> = {
  setContasB3(state, contasB3: IQueryContaB3) {
    state.contasB3 = contasB3;
    webStorage.setItem('contasB3', contasB3);
  },
};

const actions: ActionTree<any, RootState> = {
  async buscarContasB3({ commit }) {
    const usuario = webStorage.getItem<IUsuarioModel>('usuario')
    const tenantId = usuario?.tenantAssinei ?? ''
    const credenciaisCredor = (await ServiceDocRegistration.getCredicaisCredor(tenantId)).data;
    const contasB3Obtidas = { } as IQueryContaB3

    if(credenciaisCredor.contasB3.length > 0) {
      contasB3Obtidas.id = credenciaisCredor.id
      credenciaisCredor.contasB3.forEach((conta) => {
        const key = 'conta' + conta.miolo;
        contasB3Obtidas[key] = conta;
      });
    }

    commit('setContasB3', contasB3Obtidas || contasB3)
  },
  /** @todo trazer lógica de solicitação para a store */
  async definirContaB3({ commit }, contasB3Atualizadas:IQueryContaB3) {
    // if (contasB3Atualizadas.id) {
    //   await serviceDocRegistration.atualizarCredorContas(this.formB3);
    // } else {
    //   await serviceDocRegistration.credenciarCredor(this.formB3);
    // }
    commit('setContasB3', contasB3Atualizadas || contasB3)
  },

};

// eslint-disable-next-line
function gerenciarContas(formContas:IQueryContaB3) {
  const chaves = Object.keys(formContas);
  const contas: IContaB3[] = [];
  let contaB3 = { ...contaB3Default }
  for (let i = 0; i < chaves.length; i++) {
    const chave = chaves[i];
    const conta = formContas[chave].stringContaB3.split('-');
    contaB3 = {
      ...formContas[chave],
      prefixo: conta[0],
      miolo: conta[1],
      digito: conta[2]
    };
    contas.push(contaB3);
  }
  //set contas
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions 
};
