<template>
  <div class="container-emitentes">
    <div class="container-emitentes__head">
      <RouterLink to="/emitentes" class="p1">
        <i class="el-icon-arrow-left" />
        Emitentes
      </RouterLink>

      <h2>{{ emitenteId ? 'Editar' : 'Novo' }} Emitente</h2>
    </div>
    <div class="container-emitentes__body">
      <ul class="container-emitentes__steps">
        <RouterLink
          class="container-emitentes__steps-item p1"
          v-for="item in steps"
          :key="item.path"
          :to="`#${item.path}`"
        >
          {{ item.label }}
        </RouterLink>
      </ul>

      <cpr-form
        class="container-emitentes__form"
        :formModel="formEmitente"
        :submit="submitForm"
        :load-submit="loadSubmit"
        :cancel="back"
      >
        <cpr-label label="Identificação" id="identificacao" />

        <el-form-item prop="tipoCliente" label="Tipo do emitente:">
          <el-radio-group
            v-model="formEmitente.tipoCliente"
            @change="changeTipoPessoa"
            :disabled="emitenteId ? true : false"
          >
            <el-radio label="PF">Pessoa Física</el-radio>
            <el-radio label="PJ">Pessoa Jurídica</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-for="(item, index) in formEmitente.inscricoesEstaduais"
          :key="`insc${index}`"
          :prop="'inscricoesEstaduais.' + index + '.numInscricaoEstadual'"
          :label="`Inscrição Estadual ${index + 1}`"
        >
          <cpr-input
            v-model="item.numInscricaoEstadual"
            v-if="formEmitente.inscricoesEstaduais"
          >
            <template #action>
              <el-button
                class="input__icon icon-button"
                icon="el-icon-delete"
                v-if="
                  !item.inscricaoEstadualId &&
                  (index !== 0 || formEmitente.inscricoesEstaduais.length > 1)
                "
                @click="deleteValue('inscricaoEstadual', index)"
              />
              <el-button
                class="input__icon icon-button"
                icon="el-icon-plus"
                v-if="index === formEmitente.inscricoesEstaduais.length - 1"
                @click="addValue('inscricaoEstadual')"
              />
            </template>
          </cpr-input>
        </el-form-item>

        <!--#region PF  -->
        <div v-show="formEmitente.tipoCliente === 'PF'">
          <el-form-item
            prop="nome"
            label="Nome"
            :rules="validationPessoaFisica(['required'])"
            ref="email"
          >
            <cpr-input
              v-model="formEmitente.nome"
              placeholder="Nome completo"
            />
          </el-form-item>
          <div class="is-multi">
            <el-form-item
              prop="cpf"
              label="CPF"
              v-mask="'###.###.###-##'"
              :rules="validationPessoaFisica(['required', 'cpf'])"
            >
              <cpr-input
                v-model.lazy="formEmitente.cpf"
                placeholder="000.000.000-00"
              />
            </el-form-item>

            <el-form-item prop="dataNascimento" label="Data de Nascimento">
              <cpr-input
                v-model="formEmitente.dataNascimento"
                isDate
                placeholder="DD/MM/AAAA"
              />
            </el-form-item>
          </div>
          <div class="is-multi">
            <el-form-item prop="rg" label="RG">
              <cpr-input v-model="formEmitente.rg" placeholder="00000000" />
            </el-form-item>
            <el-form-item prop="orgaoExpedidorRg" label="Orgão Expedidor">
              <cpr-input
                v-model="formEmitente.orgaoExpedidorRg"
                placeholder="SSP"
              />
            </el-form-item>
            <el-form-item prop="emissaoRg" label="Emissão">
              <cpr-input
                v-model="formEmitente.emissaoRg"
                isDate
                placeholder="DD/MM/AAAA"
              />
            </el-form-item>
            <el-form-item prop="nacionalidade" label="Nacionalidade">
              <cpr-input
                v-model="formEmitente.nacionalidade"
                placeholder="Brasileira"
              />
            </el-form-item>
          </div>
          <div class="is-multi">
            <el-form-item prop="naturalidade" label="Naturalidade">
              <cpr-input v-model="formEmitente.naturalidade" />
            </el-form-item>
            <el-form-item prop="profissao" label="Profissão">
              <cpr-input v-model="formEmitente.profissao" placeholder="Admin" />
            </el-form-item>
            <el-form-item
              prop="estadoCivil"
              label="Estado Civil"
              :rules="validationPessoaFisica(['required'])"
            >
              <cpr-input
                v-model="formEmitente.estadoCivil"
                placeholder="Selecione o estado civil"
                isSelect
                @change="(ec) => changeEstadoCivil(ec)"
              >
                <el-option
                  v-for="item in estadocivil"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </cpr-input>
            </el-form-item>
          </div>
          <div class="is-multi filiacao">
            <el-form-item prop="filiacao" label="Filiação">
              <cpr-input v-model="formEmitente.filiacao" />
            </el-form-item>
          </div>
          <div v-if="ehCasado && formEmitente.conjuge">
            <cpr-label label="Conjuge" />
            <el-form-item prop="conjuge.nome" label="Nome">
              <cpr-input v-model="formEmitente.conjuge.nome" />
            </el-form-item>
            <div class="is-multi">
              <el-form-item
                prop="conjuge.cpf"
                label="CPF"
                :rules="validationPessoaFisica(['cpf'])"
              >
                <cpr-input
                  v-model="formEmitente.conjuge.cpf"
                  placeholder="000.000.000-00"
                  v-mask="'###.###.###-##'"
                />
              </el-form-item>

              <el-form-item
                prop="conjuge.dataNascimento"
                label="Data de Nascimento"
              >
                <cpr-input
                  v-model="formEmitente.conjuge.dataNascimento"
                  isDate
                  placeholder="DD/MM/AAAA"
                />
              </el-form-item>
            </div>
            <div class="is-multi">
              <el-form-item prop="conjuge.rg" label="RG">
                <cpr-input
                  v-model="formEmitente.conjuge.rg"
                  placeholder="00000000"
                />
              </el-form-item>
              <el-form-item
                prop="conjuge.orgaoExpedidorRg"
                label="Orgão Expedidor"
              >
                <cpr-input
                  v-model="formEmitente.conjuge.orgaoExpedidorRg"
                  placeholder="SSP"
                />
              </el-form-item>
              <el-form-item prop="conjuge.emissaoRg" label="Emissão">
                <cpr-input
                  v-model="formEmitente.conjuge.emissaoRg"
                  isDate
                  placeholder="DD/MM/AAAA"
                />
              </el-form-item>
              <el-form-item prop="conjuge.nacionalidade" label="Nacionalidade">
                <cpr-input
                  v-model="formEmitente.conjuge.nacionalidade"
                  placeholder="Brasileira"
                />
              </el-form-item>
            </div>
            <div class="is-multi">
              <el-form-item prop="conjuge.naturalidade" label="Naturalidade">
                <cpr-input
                  v-model="formEmitente.conjuge.naturalidade"
                  placeholder="Goiânia"
                />
              </el-form-item>

              <el-form-item prop="conjuge.profissao" label="Profissão">
                <cpr-input
                  v-model="formEmitente.conjuge.profissao"
                  placeholder="Engenheiro"
                />
              </el-form-item>

              <el-form-item
                prop="conjuge.email"
                label="Email"
                :rules="validationPessoaFisica(['email'])"
              >
                <cpr-input
                  v-model="formEmitente.conjuge.email"
                  placeholder="email@email.com"
                />
              </el-form-item>
              <cpr-form-input
                v-model="formEmitente.conjuge.telefone"
                prop="conjuge.telefone"
                placeholder="(DD) XXXXX-XXXX"
                label="Telefone"
                mask="telefone"
              />
            </div>
            <el-form-item prop="conjuge.filiacao" label="Filiação">
              <cpr-input v-model="formEmitente.conjuge.filiacao" />
            </el-form-item>
          </div>
        </div>
        <!--#endregion -->

        <!--#region PJ  -->
        <div v-if="formEmitente.tipoCliente === 'PJ'">
          <el-form-item
            prop="razaoSocial"
            label="Razão Social"
            :rules="validationPessoaJuridica(['required'])"
          >
            <cpr-input
              v-model="formEmitente.razaoSocial"
              placeholder="Razão Social"
            />
          </el-form-item>

          <div class="is-multi">
            <el-form-item
              prop="nomeFantasia"
              label="Nome Fantasia"
              :rules="validationPessoaJuridica(['required'])"
            >
              <cpr-input
                v-model="formEmitente.nomeFantasia"
                placeholder="Nome fantasia"
              />
            </el-form-item>

            <cpr-form-input
              v-model="formEmitente.cnpj"
              prop="cnpj"
              label="CNPJ"
              :rules="['required', 'cnpj']"
              mask="cnpj"
            />
          </div>
          <cpr-label label="Representante" id="representante" />

          <template v-if="formEmitente.representante">
            <el-form-item
              prop="representante.nome"
              label="Nome"
              :rules="validationPessoaJuridica(['required'])"
            >
              <cpr-input v-model="formEmitente.representante.nome" />
            </el-form-item>

            <div class="is-multi">
              <el-form-item
                prop="representante.cpf"
                label="CPF"
                :rules="validationPessoaJuridica(['required', 'cpf'])"
                v-mask="'###.###.###.###-##'"
              >
                <cpr-input
                  v-model="formEmitente.representante.cpf"
                  placeholder="000.000.000-00"
                />
              </el-form-item>
              <el-form-item prop="representante.rg" label="RG">
                <cpr-input v-model="formEmitente.representante.rg" />
              </el-form-item>

              <el-form-item
                prop="estadoCivil"
                label="Estado Civil"
                :rules="validationPessoaFisica(['required'])"
              >
                <cpr-input
                  v-model="formEmitente.representante.estadoCivil"
                  placeholder="Selecione o estado civil"
                  isSelect
                  @change="(ec) => changeEstadoCivilRepresentante(ec)"
                >
                  <el-option
                    v-for="item in estadocivil"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </cpr-input>
              </el-form-item>
            </div>

            <div class="is-multi">
              <el-form-item
                prop="representante.email"
                label="E-mail"
                :rules="validationPessoaJuridica(['required', 'email'])"
              >
                <cpr-input v-model="formEmitente.representante.email" />
              </el-form-item>

              <cpr-form-input
                v-model="formEmitente.representante.telefone"
                prop="representante.telefone"
                placeholder="(DD) XXXXX-XXXX"
                label="Telefone"
                mask="telefone"
              />

              <el-form-item prop="representante.cargo" label="Cargo">
                <cpr-input v-model="formEmitente.representante.cargo" />
              </el-form-item>

              <el-form-item prop="nacionalidade" label="Nacionalidade">
                <cpr-input
                  v-model="formEmitente.representante.nacionalidade"
                  placeholder="Brasileira"
                />
              </el-form-item>
            </div>

            <div class="is-multi">
              <div
                v-if="
                  representanteEhCasado &&
                  formEmitente.representante &&
                  formEmitente.representante.conjuge
                "
              >
                <cpr-label label="Conjuge do Representante" />
                <cpr-form-input
                  v-model="formEmitente.representante.conjuge.nome"
                  prop="conjuge.nome"
                  label="Nome"
                />

                <div class="is-multi">
                  <cpr-form-input
                    v-model="formEmitente.representante.conjuge.cpf"
                    prop="representante.conjuge.cpf"
                    label="CPF"
                    :rules="['cpf']"
                    placeholder="000.000.000-00"
                    mask="cpf"
                  />

                  <cpr-form-input
                    v-model="formEmitente.representante.conjuge.dataNascimento"
                    prop="conjuge.dataNascimento"
                    label="Data de Nascimento"
                    isDate
                    placeholder="DD/MM/AAAA"
                  />
                </div>

                <div class="is-multi">
                  <cpr-form-input
                    v-model="formEmitente.representante.conjuge.rg"
                    prop="conjuge.rg"
                    label="RG"
                    placeholder="00000000"
                  />

                  <cpr-form-input
                    v-model="
                      formEmitente.representante.conjuge.orgaoExpedidorRg
                    "
                    prop="conjuge.orgaoExpedidorRg"
                    label="Orgão Expedidor"
                    placeholder="SSP"
                  />

                  <cpr-form-input
                    v-model="formEmitente.representante.conjuge.emissaoRg"
                    prop="conjuge.emissaoRg"
                    label="Emissão"
                    isDate
                    placeholder="DD/MM/AAAA"
                  />

                  <cpr-form-input
                    v-model="formEmitente.representante.conjuge.nacionalidade"
                    prop="conjuge.nacionalidade"
                    label="Nacionalidade"
                    placeholder="Brasileira"
                  />
                </div>

                <div class="is-multi">
                  <cpr-form-input
                    v-model="formEmitente.representante.conjuge.naturalidade"
                    prop="conjuge.naturalidade"
                    label="Naturalidade"
                  />

                  <cpr-form-input
                    v-model="formEmitente.representante.conjuge.profissao"
                    prop="conjuge.profissao"
                    label="Profissão"
                  />

                  <cpr-form-input
                    v-model="formEmitente.representante.conjuge.email"
                    prop="representante.conjuge.email"
                    label="Email"
                    :rules="['email']"
                    placeholder="email@email.com"
                  />

                  <cpr-form-input
                    v-model="formEmitente.representante.conjuge.telefone"
                    prop="conjuge.telefone"
                    placeholder="(DD) XXXXX-XXXX"
                    label="Telefone"
                    mask="telefone"
                  />
                </div>
                <cpr-form-input
                  v-model="formEmitente.representante.conjuge.filiacao"
                  prop="conjuge.filiacao"
                  label="Filiação"
                />
              </div>
            </div>
          </template>
        </div>
        <!--#endregion -->

        <cpr-label label="Contato" id="contato" />
        <template v-if="formEmitente.contato">
          <el-form-item
            v-for="(item, index) in formEmitente.contato.emailEndereco"
            :key="`email${index}`"
            :prop="`contato.emailEndereco.${index}.emailEndereco`"
            :label="`Email ${index + 1}`"
            :rules="$validationForm(['required', 'email'])"
          >
            <cpr-input
              v-model="item.emailEndereco"
              placeholder="email@email.com"
            >
              <template #action>
                <el-button
                  class="input__icon icon-button"
                  icon="el-icon-delete"
                  v-if="!item.emailId && index !== 0"
                  @click="deleteValue('emailEndereco', index)"
                />
                <el-button
                  class="input__icon icon-button"
                  icon="el-icon-plus"
                  v-if="index === formEmitente.contato.emailEndereco.length - 1"
                  @click="addValue('emailEndereco')"
                />
              </template>
            </cpr-input>
          </el-form-item>

          <el-form-item
            v-for="(item, index) in formEmitente.contato.numero"
            :key="`cell${index}`"
            :prop="`contato.numero.${index}.numero`"
            :label="`Celular ${index + 1}`"
            :rules="$validationForm(['required'])"
          >
            <cpr-input
              v-model="item.numero"
              placeholder="(xx) xxxxx-xxxx"
              v-mask="'(##) #####-####'"
            >
              <template #action>
                <el-button
                  class="input__icon icon-button"
                  icon="el-icon-delete"
                  v-if="!item.telefoneId && index !== 0"
                  @click="deleteValue('celular', index)"
                />
                <el-button
                  class="input__icon icon-button"
                  icon="el-icon-plus"
                  v-if="index === formEmitente.contato.numero.length - 1"
                  @click="addValue('celular')"
                />
              </template>
            </cpr-input>
          </el-form-item>
        </template>
        <cpr-label label="Endereço" id="endereco" />
        <div class="is-multi">
          <el-form-item prop="cep" label="CEP" style="width: 30%">
            <cpr-input
              v-model.lazy="formEmitente.cep"
              placeholder="00000-000"
              v-mask="'#####-###'"
              @change="(value) => searchEndereco(value)"
              :suffix-icon="`${loadCep ? 'el-icon-loading' : ''}`"
            />
          </el-form-item>

          <el-form-item prop="rua" label="Rua">
            <cpr-input v-model="formEmitente.rua" placeholder="Rua X" />
          </el-form-item>
          <el-form-item prop="complemento" label="Complemento">
            <cpr-input
              v-model="formEmitente.complemento"
              placeholder="Endereço completo do emitente"
            />
          </el-form-item>
        </div>
        <div class="is-multi">
          <el-form-item prop="bairro" label="Bairro">
            <cpr-input v-model="formEmitente.bairro" placeholder="Bairro" />
          </el-form-item>
          <el-form-item prop="cidade" label="Cidade">
            <cpr-input v-model="formEmitente.cidade" placeholder="Cidade" />
          </el-form-item>
          <el-form-item
            prop="uf"
            label="UF"
            :rules="$validationForm([{ max: 2 }])"
          >
            <cpr-input 
              v-model="formEmitente.uf"  
              placeholder="UF"
              maxlength="2"
              v-uppercase 
            />
          </el-form-item>
          <el-form-item prop="numero" label="Numero">
            <cpr-input v-model="formEmitente.numero" placeholder="S/N" />
          </el-form-item>
        </div>

        <template v-if="formEmitente.tipoCliente === 'PF'">
          <cpr-label
            label="Documentos pessoais"
            id="documentosPessoais"
            style="margin: 20px 0 10px"
          />
          <cpr-upload-preview
            v-model="arquivosEnderecos"
            :limit="5"
            :delete-async="handleDeleteArquivo"
            @delete="handleDeleteForm"
          />

          <cpr-label
            label="Comprovante de Endereço"
            id="comprovanteEndereco"
            style="margin: 20px 0 10px"
          />

          <cpr-upload-preview
            v-model="arquivosDocPessoais"
            :limit="5"
            :delete-async="handleDeleteArquivo"
            @delete="handleDeleteForm"
          />
        </template>
      </cpr-form>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import ServiceCliente from '@/services/Cliente'
import CprForm from '@/components/cpr-form/cpr-form.vue'
import Input from '@/components/Input.vue'
import CprUploadPreview from '@/components/cpr-upload/cpr-upload-preview.vue'
import Label from '@/components/Label.vue'
import webStorage from '@/common/webStorage'
import { mapperFile } from '@/utils/mappers/upload'
import { TipoPessoa } from '@/Enums/pessoa'
import {
  IClienteArquivo,
  IClienteModel,
  TipoClienteArquivo
} from '@/models/cliente'

import type { IRepresentanteModel } from '@/models/representante'
import type { IConjugeModel } from '~/models/conjuge'
import type { IContatoModel } from '~/models/contato'
import type { IUsuarioModel } from '@/types/usuario'
import type { IFile } from '~/types/file'
import type { ValidationRule } from '~/utils/validationForm'

@Component({
  components: {
    CprForm,
    'cpr-input': Input,
    'cpr-label': Label,
    CprUploadPreview
  }
})
export default class FormEmitentes extends Vue {
  formEmitente: IClienteModel = {
    tipoCliente: TipoPessoa.PESSOA_FISICA,
    nomeFantasia: '',
    razaoSocial: '',
    cnpj: '',
    nome: '',
    rg: '',
    cpf: '',
    estadoCivil: '',
    cep: '',
    rua: '',
    numero: '',
    conjuge: {} as IConjugeModel,
    contato: {
      emailEndereco: [
        {
          emailEndereco: ''
        }
      ],
      numero: [
        {
          numero: ''
        }
      ]
    } as IContatoModel,
    complemento: '',
    bairro: '',
    uf: '',
    cidade: '',
    orgaoExpedidorRg: '',
    nacionalidade: '',
    inscricoesEstaduais: [
      {
        numInscricaoEstadual: ''
      }
    ],
    profissao: '',
    representante: {
      conjuge: {} as IConjugeModel
    } as IRepresentanteModel
  }

  usuario = {} as IUsuarioModel
  arquivosEnderecos: IFile[] = []
  arquivosDocPessoais: IFile[] = []

  emitenteId = ''
  loadCep = false
  resetFilds = false
  loadSubmit = false
  atualizarTitulos = false

  validationPessoaFisica(validations: ValidationRule[]) {
    return this.formEmitente.tipoCliente === TipoPessoa.PESSOA_FISICA
      ? this.$validationForm(validations)
      : ['']
  }

  validationPessoaJuridica(validations: ValidationRule[]) {
    return this.formEmitente.tipoCliente === TipoPessoa.PESSOA_JURIDICA
      ? this.$validationForm(validations)
      : ['']
  }

  get isInscricaoEstadual() {
    return (this.formEmitente.inscricoesEstaduais?.length ?? 0) - 1
  }

  get steps() {
    return this.formEmitente.tipoCliente === 'PF'
      ? [
          {
            path: 'identificacao',
            label: 'Identificação'
          },
          {
            path: 'contatos',
            label: 'Contatos'
          },
          {
            path: 'endereco',
            label: 'Endereço'
          },
          {
            path: 'documentosPessoais',
            label: 'Documentos pessoais'
          }
        ]
      : [
          {
            path: 'identificacao',
            label: 'Identificação'
          },
          {
            path: 'representantes',
            label: 'Representante'
          },
          {
            path: 'contatos',
            label: 'Contatos'
          },
          {
            path: 'endereco',
            label: 'Endereço'
          }
        ]
  }

  get estadocivil() {
    return [
      {
        value: 'Solteiro',
        label: 'Solteiro'
      },
      {
        value: 'Casado',
        label: 'Casado'
      },
      {
        value: 'Divorciado',
        label: 'Divorciado'
      },
      {
        value: 'Convivente',
        label: 'Convivente'
      },
      {
        value: 'Viuvo',
        label: 'Viúvo'
      },
      {
        value: 'Outro',
        label: 'Outro'
      }
    ]
  }

  get ehCasado() {
    return this.formEmitente.estadoCivil === 'Casado'
  }

  get representanteEhCasado() {
    return this.formEmitente.representante?.estadoCivil === 'Casado'
  }

  get isInscricoesEstaduais() {
    return (this.formEmitente.inscricoesEstaduais?.length ?? 0) > 0
  }

  async created() {
    this.emitenteId = this.$route.params.id
    this.usuario = webStorage.getItem('usuario')
    if (this.emitenteId) {
      try {
        this.formEmitente = (
          await ServiceCliente.buscaCliente(this.emitenteId)
        ).data
        const representante =
          this.formEmitente.tipoCliente === 'PJ' &&
          !this.formEmitente.representante
            ? ({} as IRepresentanteModel)
            : this.formEmitente.representante

        const inscricoesEstaduais = this.isInscricoesEstaduais
          ? this.formEmitente?.inscricoesEstaduais
          : [{ numInscricaoEstadual: '' }]

        this.formEmitente = {
          ...this.formEmitente,
          cep: this.$formatarCEP(this.formEmitente.cep, true),
          representante: representante,
          inscricoesEstaduais
        }

        const arquivosEnderecos =
          this.formEmitente.clienteArquivos?.filter(
            (ca) =>
              ca.tipoClienteArquivo ===
              TipoClienteArquivo.COMPROVANTES_RESIDENCIAS
          ) ?? []
        const arquivosDocPessoais =
          this.formEmitente.clienteArquivos?.filter(
            (ca) =>
              ca.tipoClienteArquivo === TipoClienteArquivo.DOCUMENTOS_PESSOAIS
          ) ?? []

        this.arquivosEnderecos = arquivosEnderecos?.map((ca) =>
          mapperFile(ca, ca?.clienteArquivoId)
        )
        this.arquivosDocPessoais = arquivosDocPessoais?.map((ca) =>
          mapperFile(ca, ca?.clienteArquivoId)
        )
      } catch (error) {
        this.$showMessagesErrors(error)
      }
    }
  }

  addValue(type: string) {
    switch (type) {
      case 'celular':
        this.formEmitente.contato?.numero.push({ numero: '' })
        break

      case 'emailEndereco':
        this.formEmitente.contato?.emailEndereco.push({ emailEndereco: '' })
        break

      case 'inscricaoEstadual':
        this.formEmitente.inscricoesEstaduais?.push({
          numInscricaoEstadual: ''
        })
        break

      default:
        break
    }
  }

  deleteValue(type: string, index: number) {
    switch (type) {
      case 'celular':
        this.formEmitente.contato?.numero.splice(index, 1)
        break

      case 'emailEndereco':
        this.formEmitente.contato?.emailEndereco.splice(index, 1)
        break

      case 'inscricaoEstadual':
        this.formEmitente.inscricoesEstaduais?.splice(index, 1)
        break

      default:
        break
    }
  }

  async handleDeleteArquivo(file: IFile) {
    await ServiceCliente.delteteArquivoById(file.id as number)
  }

  handleDeleteForm(index: number) {
    this.formEmitente?.clienteArquivos?.splice(index, 1)
  }

  mapperClienteArquivo(
    key: 'arquivosEnderecos' | 'arquivosDocPessoais'
  ): IClienteArquivo[] {
    return this[key].map(
      (a): IClienteArquivo => ({
        clienteArquivoId: a?.id as number,
        clienteId: this.formEmitente.clienteId,
        documentoBase64: a?.base64String ?? '',
        urlDocumento: a.url,
        tamanhoArquivo: a.size,
        extensao: a.type,
        nomeArquivo: a.name,
        tipoClienteArquivo:
          key === 'arquivosDocPessoais'
            ? TipoClienteArquivo.DOCUMENTOS_PESSOAIS
            : TipoClienteArquivo.COMPROVANTES_RESIDENCIAS
      })
    )
  }

  changeEstadoCivil(estadoCivil: string) {
    estadoCivil === 'Casado' &&
    (this.formEmitente.conjuge === null || undefined)
      ? (this.formEmitente.conjuge = {} as IConjugeModel)
      : ''
  }

  changeEstadoCivilRepresentante(estadoCivil: string) {
    if (
      estadoCivil === 'Casado' &&
      this.formEmitente.representante &&
      (this.formEmitente.representante.conjuge === null ||
        this.formEmitente.representante.conjuge === undefined)
    ) {
      this.formEmitente.representante.conjuge = {} as IConjugeModel
    }
  }

  changeTipoPessoa(tipoPessoa: TipoPessoa) {
    this.formEmitente.tipoCliente = tipoPessoa
    this.resetFilds = !this.resetFilds
  }

  searchEndereco(search: string) {
    this.loadCep = !this.loadCep
    const cep = this.$formatarCEP(search)
    ServiceCliente.buscaCep(cep)
      .then((data) => {
        this.formEmitente.rua = data.logradouro
        this.formEmitente.bairro = data.bairro
        this.formEmitente.uf = data.uf
        this.formEmitente.cidade = data.localidade
      })
      .catch((error) => {
        this.$showMessagesErrors(error)
      })
      .finally(() => {
        this.loadCep = !this.loadCep
      })
  }

  async submitForm() {
    
    this.loadSubmit = true

    this.formEmitente = {
      ...this.formEmitente,
      cep: this.formEmitente.cep.replace(/[^0-9]/g, ''),
      empresaId: this.usuario.empresaId
    }

    if (!!this.arquivosDocPessoais)
      this.formEmitente.clienteArquivos = this.mapperClienteArquivo(
        'arquivosDocPessoais'
      )

    if (!!this.arquivosEnderecos) {
      if (!this.formEmitente.clienteArquivos)
        this.formEmitente.clienteArquivos = []
      this.formEmitente.clienteArquivos.push(
        ...this.mapperClienteArquivo('arquivosEnderecos')
      )
    }

    const payload = this.$removeEmptyProperties(
      this.formEmitente
    ) as IClienteModel

    try {
      if (this.emitenteId) {
        this.$cprAlert({
          type: 'warning',
          title: 'Salvar Emitente',
          textInfo:
            'Gostaria de alterar os dados dos títulos que estão com o status "Em criação" que possuem esse emitente no cadastro?',
          namePositiveAction: 'Sim',
          nameNegativeAction: 'Não',
          positiveAction: async () => await this.atualizarEmitente(payload, true),
          negativeAction: async () => await this.atualizarEmitente(payload, false)
        })
      } else {
        await ServiceCliente.adicionarCliente(payload)
        this.back()
        this.$showNotify(
          'success',
          'Emitente criado com sucesso!'
        )
      }

    } catch (error) {
      this.$showMessagesErrors(error)
    }  finally {
      this.loadSubmit = false
    }
  }

  back() {
    this.$router.push('/emitentes')
  }

  async atualizarEmitente(payload, atualizarTitulos) {
    try {
      this.loadSubmit = true
      this.atualizarTitulos = atualizarTitulos
      await ServiceCliente.atualizarCliente(
        this.emitenteId,
        this.atualizarTitulos,
        payload
      )
      const msg = this.atualizarTitulos
        ? `Emitente: ${this.abreviarNome(
            payload.nome
          )} editado com sucesso. Títulos em Criação atualizados!`
        : `Emitente: ${this.abreviarNome(
            payload.nome
          )} editado com sucesso. Próximos títulos criados terão os dados do emitente atualizados.`

      this.$showNotify('success', msg)
      this.back()
    } catch (error) {
      this.$showMessagesErrors(error)
    } finally {
      this.loadSubmit = false
    }
  }
  abreviarNome(nome: string) {
    const partes = nome.split(' ')
    if (partes.length < 3) return nome
    const res = partes.slice(0, 2).join(' ')
    return res
  }
}
</script>

<style lang="scss" scoped>
.container {
  &-emitentes {
    &__head {
      top: 112px;
      display: flex;
      flex-direction: column;
      position: fixed;
      align-items: center;

      a {
        color: $--color-gray-6;
        display: flex;
        align-items: center;
      }

      h2 {
        color: $--color-gray-7;
        margin-top: 4px;
      }
    }

    &__body {
      display: flex;
      margin-top: 36px;
    }

    &__steps {
      top: 212px;
      width: fit-content;
      padding: 32px;
      position: fixed;
      box-shadow: $--box-shadow-base;
      border-radius: 4px;
      background-color: $--color-gray-1;

      &-item {
        color: $--color-gray-6;
        cursor: pointer;
        display: flex;
        align-items: center;
        transition: all 0.4s ease-in-out;

        &:hover {
          color: $--color-gray-7;
        }

        &:not(:first-child) {
          margin-top: 12px;
        }

        &.router-link-active {
          color: $--color-primary;

          &::before {
            background-color: $--color-primary;
          }
        }
      }
    }

    &__form {
      width: 100%;
      margin: 100px 0 0 280px;

      .upload-documentos {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }

    & .is-multi {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      & > :nth-child(n) {
        width: 100%;
      }
    }

    .table__actions {
      display: flex;
      justify-content: center;

      & > :nth-child(n) {
        font-size: 18px;
      }

      & > :nth-child(1) {
        padding-right: 6px;
      }
    }
  }
}

.margin-bottom {
  margin-bottom: 0px;
}
</style>
