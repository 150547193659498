import './styles/index.scss';
import './plugins';
import '~/directives';
import Vue from 'vue';
import ElementUI from 'element-ui';
import VueTheMask from 'vue-the-mask';
import VueCurrencyInput from 'vue-currency-input';
import locale from 'element-ui/lib/locale/lang/pt-br';
import App from './App.vue';
import router from './router';
import { RichTextEditorPlugin } from '@syncfusion/ej2-vue-richtexteditor';
import moment from 'moment';
import ApiConfig from './config/apiConfig';
import methodsGlobal from './prototype';
import store from './store';

Object.assign(Vue.prototype, methodsGlobal);
moment.locale('pt-br');

const pluginOptions = {
  globalOptions: {
    currency: null,
    distractionFree: false,
    valueAsInteger: true,
    autoDecimalMode: true,
  },
};

Vue.use(RichTextEditorPlugin);
Vue.use(ElementUI, { locale });
Vue.use(VueTheMask);
Vue.use(VueCurrencyInput, pluginOptions);

Vue.config.productionTip = false;

ApiConfig.set()
  .then(()=>{
    new Vue({
      store,
      router,
      render: (h) => h(App),
    }).$mount('#app');
  })
.catch();
