import { decodedToken } from '~/utils/methods';
import type { IUsuarioModel } from '~/types/usuario';

const isPermitedAdmin = (usuario:IUsuarioModel) => {
  if (!usuario) return false;
  return usuario.admin;
}

const isCompanyOwner = (token:string) => {
  if (!token) return false;
  const decoded = decodedToken(token);
  return process.env.VUE_APP_EMPRESA_ADM === decoded.empresaId;
}

 const PermissionAdmin = <T extends IUsuarioModel>(obj: T) => ({
  accessEmpresaAdm: isCompanyOwner(obj?.token),
  usuarioAdm: isPermitedAdmin(obj)
});

export default PermissionAdmin 
