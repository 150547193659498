<template>
  <section class="form-section" :id="`section-${prop}`">
    <h2 v-if="label">{{ label }}</h2>
    <slot/>
  </section>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'cpr-form-section'
})
export default class CpFormSection extends Vue {
  @Prop({ default: '' }) label!:string
  @Prop({ default: '', required: true }) prop!:string

}
</script>

<style lang="scss" scoped>
@import '../../styles/mixings/_responsive-grid';

section {
  @include responsive-grid;

  h2 {
    width: 100%;
    display: flex;
    border-bottom: 1px solid get-color(primary, pure);
    font-size: 18px;
    font-size: 18px;
    line-height: 22px;
    color:  get-color(fonte, text-dark);
  }

  h{
    &1, &2, &3, &4, &5 {
      grid-column: 1 / -1 !important;
      padding-bottom: 3px;
      margin-bottom: 12px;
      font-style: normal;
      font-weight: 600;
    }
  }
}
</style>