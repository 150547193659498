import axios from 'axios';
import webStorage from '../common/webStorage';
import router from '../router';
import { IUsuarioModel } from '~/types/usuario';
import { IApiOKResult } from '~/types/api';

export const logout = () => {
  webStorage.clean()
  router.push('/auth/login');
  window.location.reload()
}

export const login = async ({ email, password }):Promise<IUsuarioModel> => {
  const payload = {
    email,
    password
  }
  
  const { data } = (await axios.post<IApiOKResult<IUsuarioModel>>('/autenticacao/login', payload)).data

  return data
}
