<script>
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { onlyNumbers } from '../helpers';
import IconAlert from '../assets/svgs/ic-alert.svg';

export default {
  inheritAttrs: false,

  components: {
    IconAlert,
  },

  props: {
    label: {
      type: [Boolean, String],
      default: false,
      required: false,
    },

    value: {
      type: [String, Array, Date, Number],
      required: false,
    },
    inputType: {
      type: [String],
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data: () => ({
    error: '',
    innerValue: '',
  }),
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal);
    },

    value(newVal) {
      this.innerValue = newVal;
    },
  },
  methods: {
    handleBlur() {
      const document = onlyNumbers(this.innerValue);
      if (this.inputType === 'cpf') {
        if (this.innerValue !== '') {
          this.error = !cpf.isValid(document) ? 'CPF Inválido' : '';
        }
        if (this.error) {
          this.innerValue = '';
        }
      } else {
        if (this.value !== '') {
          this.error = !cnpj.isValid(document) ? 'CNPJ Inválido' : '';
        }
        if (this.error) {
          this.innerValue = '';
        }
      }
      this.$emit('blur', this.innerValue);
    },
  },
};
</script>

<template>
  <div :class="['input', !! error && 'is-error', !error &&  'margin-bottom']">
    <label
      v-if="label"
      v-text="label"
      :style="{ color: required ? '#DC3545' : '#2e3a59' }"
    />

    <div class="input__target input__cpf">
      <el-input
        v-bind="$attrs"
        v-model="innerValue"
        @blur="handleBlur"
        :placeholder="inputType === 'cpf' ?  '000.000.000-00' : '00.000.000/0000-00'"
        v-mask="inputType === 'cpf' ? '###.###.###-##': '##.###.###/####-##'"
      >
        <template
          #suffix
          v-if="!! error"
        >
          <i class="input__icon-alert">
            <IconAlert/>
          </i>
        </template>
      </el-input>
      <slot name="action" />
    </div>

    <p
      v-if="!! error"
      class="input__error p3"
      v-text="error"
    />
  </div>
</template>

<style lang="scss">

.input {
  width: 100%;
  display: flex;
  flex-direction: column;

  label {
    color: $--color-gray-7;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 2px;
  }

  &__icon {
    margin-left: 16px;
    background-color: $--color-gray-1;

    &-alert {
      margin-top: 10px;
    }
  }

  &__cpf {
    .el-input__suffix {
      display: flex;
      padding-right: 4px;
      align-items: center;
    }
  }

  &__target {
    display: flex;
    align-items: center;

    .el-input, .el-input-number {
      width: 100%;
      .el-input__clear {
        font-size: 16px;
        margin-right: 14px;
      }

      &__inner {
        color: $--color-gray-7;
      }

      &--suffix &__inner {
        padding-right: 48px;
        width: 100%;
      }
    }

    .el-date-editor.el-input {
      width: 100%;
    }
  }

  &__number {
    .el-input {
      input {
        text-align: left;
      }
    }
  }

  &__error {
    color: #F56C6C;
    margin-top: 4px;
  }

  &.is-error .el-input {
    &__inner {
      border-color: #F56C6C;
    }

    &__suffix-inner {
      color: #F56C6C;
    }
  }
}
</style>
