import Vue from 'vue';

Vue.directive('uppercase', {
  bind(el, binding, vnode) {
    const toUpperCase = (e) => {
      e.target.value = e.target.value.toUpperCase();
      if(vnode.componentInstance) {
        vnode.componentInstance.$emit('input', e.target.value);
      }
    };

    el.addEventListener('input', toUpperCase);
  },

  update(el, binding, vnode) {
    const input = el as HTMLInputElement;

    if (input.value) {
      input.value = input.value.toUpperCase();
      vnode?.componentInstance?.$emit('input', input.value);
    }
  },

  // componentUpdated(el, binding, vnode) {
  // },
  // inserted(el, binding, vnode) {
  // },
});