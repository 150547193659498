<script>
import axios from 'axios';
import CprLabel from '@/components/Label.vue';
import IntInput from '@/components/Input.vue';
import CprForm from '@/components/cpr-form/cpr-form.vue';
import ModalRepresentanteFavorecido from './ModalRepresentanteFavorecido.vue';
import IconArrow from '@/assets/svgs/icon-arrow-left.svg';
import IconDelete from '@/assets/svgs/icon-delete.svg';
import ServiceFavorecido from '@/services/Favorecido';

export default {
  name: 'form-favorecidos',
  components: {
    CprLabel,
    CprForm,
    IntInput,
    IconArrow,
    IconDelete,
    ModalRepresentanteFavorecido
  },
  async mounted() {
    this.favorecidoId = this.$route.params.id;

    if (this.favorecidoId) {
      try {
        const { data } = await ServiceFavorecido.getById(this.favorecidoId);
        this.initializeForm(data);
      } catch (er) {
        const msgm =
          er.errors?.find((m) => m) ?? 'Houve um problema ao buscar os dados!';
        this.$showNotify('error', msgm);
        this.favorecidoId = '';
        this.$router.push('/favorecidos/novo');
      }
    }
  },
  data: () => ({
    step: 0,
    form: {
      razaoSocial: '',
      cnpj: [''],
      cnpjFavorecido: [
        {
          numCnpj: ''
        }
      ],
      enderecoFavorecido: [],
      representanteFavorecido: [],
      enderecoData: {
        cep: '',
        rua: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        uf: ''
      },
      representanteData: {
        representanteFavorecidoId: '',
        nome: '',
        rg: '',
        cpf: '',
        cargo: '',
        email: '',
        telefone: ''
      }
    },
    indexRepresentante: null,
    showModalRepresentanteFavorecido: false,
    feedback: false,
    alertFavorecidoData: false,
    alertCnpj: false,
    showFormEndereco: false,
    favorecidoId: ''
  }),
  computed: {
    steps() {
      return [
        {
          path: 'identificacao',
          label: 'Identificação'
        },
        {
          path: 'endereco',
          label: 'Endereço'
        },
        {
          path: 'representante',
          label: 'Representante Legal'
        }
      ];
    },
    head() {
      return [
        {
          prop: 'cep',
          label: 'CEP',
          width: '200'
        },
        {
          prop: 'endereco',
          label: 'Endereço',
          width: '450'
        },
        {
          prop: 'cidade',
          label: 'Cidade/UF',
          align: 'center'
        }
      ];
    },
    headRepresentante() {
      return [
        {
          prop: 'nome',
          label: 'Nome'
        },
        {
          prop: 'cpf',
          label: 'CPF'
        },
        {
          prop: 'email',
          label: 'Email'
        }
      ];
    },
    filteredEndereco() {
      const items = this.form.enderecoFavorecido.map((item) => ({
        cep: item.cep,
        endereco: `${item.rua}, ${item.numero} - ${item.bairro}`,
        cidade: `${item.cidade}/${item.uf}`
      }));
      return items;
    }
  },
  methods: {
    load_cep() {
      if (form.enderecoData.cep.length >= 6) {
        this.form.enderecoData.cep = form.enderecoData.cep
          .trim()
          .replace(/[^0-9]/g, '');
        axios
          .get(`/Cliente/consultaCep?cep=${this.form.cep}`)
          .then((response) => {
            this.form.enderecoData.rua = response.data.logradouro;
            this.form.enderecoData.bairro = response.data.bairro;
            this.form.enderecoData.uf = response.data.uf;
            this.form.enderecoData.cidade = response.data.localidade;
          })
          .catch((error) => {
            console.log(error.statusText);
          });
      }
    },
    load_cep_rep() {
      // const url_cep;
      this.form.representante.cep = this.form.representante.cep
        .trim()
        .replace(/[^0-9]/g, '');

      axios
        .get(`/Cliente/consultaCep?cep=${this.form.representante.cep}`)
        .then((response) => {
          this.form.representante.endereco = response.data.logradouro;
          this.form.representante.bairro = response.data.bairro;
          this.form.representante.uf = response.data.uf;
          this.form.representante.cidade = response.data.localidade;
        })
        .catch((error) => {
          console.log(error.statusText);
        });
    },
    initializeForm(formData) {
      this.form = {
        telefone: formData.telefone,
        email: formData.email,
        razaoSocial: formData.razaoSocial,
        cnpjFavorecido: formData.cnpjFavorecido,
        enderecoFavorecido: formData.enderecoFavorecido,
        representanteFavorecido: formData.representanteFavorecido,
        enderecoData: {
          cep: '',
          rua: '',
          numero: '',
          complemento: '',
          bairro: '',
          cidade: '',
          uf: ''
        },
        representanteData: {
          nome: '',
          rg: '',
          cpf: '',
          cargo: '',
          email: ''
        }
      };
    },
    isDeleteRepresentante(index) {
      return (
        typeof this.form?.representanteFavorecido[index]
          .representanteFavorecidoId === 'number'
      );
    },
    hasDataRequired() {
      let dataRequired = false;
      if (
        this.form.razaoSocial === '' ||
        this.form.razaoSocial === null ||
        this.form.cnpjFavorecido[0] === '' ||
        this.form.cnpjFavorecido[0] === null
      ) {
        dataRequired = true;
      }
      return dataRequired;
    },
    clearDataModal() {
      this.form.representanteData = {};
      this.indexRepresentante = null;
    },
    back() {
      this.$router.push('/favorecidos');
    },

    clear() {
      /**
       * @todo clear data
       */
      this.feedback = false;
    },

    async submit() {
      if (this.hasDataRequired()) {
        this.alertFavorecidoData = true;
        return;
      }
      try {
        const { form, favorecidoId } = this;
        const payload = {
          ...form,
          favorecidoId: favorecidoId,
        };
        await ServiceFavorecido.save(payload, favorecidoId);
        this.back();
        this.$showNotify(
          'success',
          `Favorecido ${favorecidoId ? 'editado' : 'cadastrado'} com sucesso!`
        );
      } catch (er) {
        this.$showMessagesErrors(er);
      }
    },
    handleModalRepresentanteFavorecido() {
      this.showModalRepresentanteFavorecido =
        !this.showModalRepresentanteFavorecido;

      if (!this.showModalRepresentanteFavorecido) this.clearDataModal();
    },
    deleteValue(type, index) {
      if (type === 'numCnpj') {
        this.form.cnpjFavorecido.splice(index, 1);
      }
    },
    addValue(type) {
      if (type === 'numCnpj') {
        this.form.cnpjFavorecido.push({ numCnpj: '' });
      }
    },

    addNewAddress() {
      this.showFormEndereco = false;
      this.form.enderecoFavorecido.push(this.form.enderecoData);
      this.form.enderecoData = {
        cep: '',
        rua: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        uf: ''
      };
    },
    addNewRepresentative(representanteData) {
      this.form.representanteFavorecido.push(representanteData);
    },
    cancelNewAddress() {
      this.showFormEndereco = false;
      this.form.enderecoData = {
        cep: '',
        rua: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        uf: ''
      };
    },
    deleteAddress(index) {
      this.form.enderecoFavorecido.splice(index, 1);
    },
    deleteRepresentante(index) {
      this.$cprAlert({
        type: 'delete',
        title: 'Excluindo Representante!',
        textInfo:
          `Você está excluindo o representante ${this.form.representanteFavorecido[index].nome}. Caso confirme, os dados serão ` +
          'excluídos e não poderão ser recuperados. O que você deseja fazer?',
        namePositiveAction: 'Manter Representante',
        nameNegativeAction: 'Excluir Representante',
        negativeAction: () => this.form.representanteFavorecido.splice(index, 1)
      });
    },
    editarRepresentante(index) {
      this.indexRepresentante = index;
      this.form.representanteData = this.form.representanteFavorecido[index];
      this.handleModalRepresentanteFavorecido();
    },
    atualizarRepresentante(data) {
      this.form.representanteFavorecido[data.index] =
        data.representanteFavorecido;
    }
  }
};
</script>

<template>
  <div class="create-favored">
    <div class="create-favored__head">
      <RouterLink to="/favorecidos" class="p1">
        <IconArrow />Favorecidos
      </RouterLink>

      <h2>{{ favorecidoId ? 'Editar' : 'Novo' }} Favorecido</h2>
    </div>

    <div class="create-favored__body" id="identificacao">
      <ul class="create-favored__steps">
        <RouterLink
          class="create-favored__steps-item p1"
          v-for="item in steps"
          :key="item.path"
          :to="`#${item.path}`"
        >
          {{ item.label }}
        </RouterLink>
      </ul>

      <cpr-form
        class="create-favored__form"
        :formModel="form"
        :submit="submit"
        :cancel="back"
      >
        <cpr-label label="Identificação do Favorecido" />
        <el-form-item
          v-for="(item, index) in form.cnpjFavorecido"
          :key="index"
          :label="`CNPJ ${index > 0 ? index + 1 : ''}`"
          :rules="$validationForm(['required', 'cnpj'])"
          :prop="'cnpjFavorecido.' + index + '.numCnpj'"
        >
          <int-input
            v-model="item.numCnpj"
            mask="cnpj"
            placeholder="00.000.000/0000-00"
            :id="'cnpjFavorecido.' + index + '.numCnpj'"
          >
            <template #action>
              <el-button
                v-if="!item.cnpjFavorecidoId && index !== 0
              "
                @click.prevent="deleteValue('numCnpj', index)"
                class="input__icon icon-button"
                icon="el-icon-delete"
              />
              <el-button
                class="input__icon icon-button margin-bottom"
                v-if="index === form.cnpjFavorecido.length - 1"
                @click.prevent="addValue('numCnpj')"
                icon="el-icon-plus"
              />
            </template>
          </int-input>
        </el-form-item>
        <div class="create-favored__form-group" id="endereco">
          <cpr-form-input 
            v-model="form.razaoSocial"
            label="Razao Social"
            placeholder="Digite aqui..."
            maxlength="100"
            prop="razaoSocial"
            :rules="['required', { max: 100 }]"
          />

          <cpr-form-input 
            v-model="form.email"
            placeholder="exemplo@dominio.com"
            maxlength="100"
            prop="email"
            label="Email"
            :rules="['email', { max: 100 }]"
          />

          <cpr-form-input 
            v-model="form.telefone"
            placeholder="(DD) XXXXX-XXXX"
            maxlength="100"
            prop="telefone"
            label="Telefone"
            :rules="[{ max: 100 }]"
            mask="telefone"
          />
        </div>

        <div class="create-favored__form-group">
          <cpr-label label="Endereço" />
          <el-row class="address-btn-new">
            <el-button @click="showFormEndereco = true" icon="el-icon-plus"
              >Novo endereço</el-button
            >
          </el-row>
          <div class="address__form" v-if="showFormEndereco">
            <el-row :gutter="20">
              <el-col :span="6">
                <int-input
                  class="address__form-group-cep"
                  label="CEP"
                  v-model="form.enderecoData.cep"
                  placeholder="00000-000"
                  v-mask="'#####-###'"
                  v-on:input="load_cep"
                />
              </el-col>
              <el-col :span="14">
                <int-input
                  class="address__form-group-rua"
                  label="Logradouro"
                  v-model="form.enderecoData.rua"
                  placeholder="Endereço completo do cliente"
                  maxlength="100"
                />
              </el-col>
              <el-col :span="4">
                <int-input
                  class="address__form-group-numero"
                  label="Número"
                  v-model="form.enderecoData.numero"
                  placeholder="Número"
                  maxlength="5"
                />
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <int-input
                  class="address__form-group-bairro"
                  label="Bairro"
                  v-model="form.enderecoData.bairro"
                  placeholder="Bairro"
                  maxlength="50"
                />
              </el-col>
              <el-col :span="8">
                <int-input
                  class="address__form-group-cidade"
                  label="Cidade"
                  v-model="form.enderecoData.cidade"
                  placeholder="Cidade"
                  maxlength="50"
                />
              </el-col>
              <el-col :span="4">
                <int-input
                  class="address__form-group-uf"
                  label="UF"
                  v-model="form.enderecoData.uf"
                  placeholder="UF"
                  maxlength="2"
                  v-uppercase 
                />
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <int-input
                  class="address__form-group-complemento"
                  label="Complemento"
                  v-model="form.enderecoData.complemento"
                  placeholder="Complemento"
                  maxlength="20"
                />
              </el-col>
              <el-col :span="10" :offset="2">
                <div class="address-action">
                  <el-button type="terciary" @click.prevent="cancelNewAddress">
                    Cancelar
                  </el-button>
                  <el-button
                    class="address-action-save"
                    @click.prevent="addNewAddress"
                    type="primary"
                  >
                    Adicionar Endereço
                  </el-button>
                </div>
              </el-col>
            </el-row>
          </div>

          <div
            class="table table-address"
            v-if="form.enderecoFavorecido.length > 0"
          >
            <el-table
              header-cell-class-name="team__header"
              :data="filteredEndereco"
            >
              <el-table-column
                v-for="item in head"
                :key="item.label"
                :prop="item.prop"
                :label="item.label"
                :width="item.width"
                :align="item.align"
              />
              <el-table-column v-if="!favorecidoId">
                <template v-slot="scope">
                  <a @click="deleteAddress(scope.$index)"><IconDelete /> </a>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <div class="create-favored__form-group" id="representante">
          <cpr-label label="Representante Legal" />

          <el-row class="address-btn-new">
            <el-button
              @click="handleModalRepresentanteFavorecido"
              icon="el-icon-plus"
              >Novo Representante Legal</el-button
            >
          </el-row>

          <div
            class="table table-address"
            v-if="form.representanteFavorecido.length > 0"
          >
            <el-table
              header-cell-class-name="team__header"
              :data="form.representanteFavorecido"
            >
              <el-table-column
                v-for="item in headRepresentante"
                :key="item.label"
                :prop="item.prop"
                :label="item.label"
                :width="item.width"
              />
              <el-table-column align="center" width="120" label="Ações">
                <template v-slot="scope">
                  <el-button-group>
                    <el-button
                      v-if="false"
                      size="mini"
                      icon="el-icon-edit"
                      @click="editarRepresentante(scope.$index)"
                    />
                    <el-button
                      v-if="!isDeleteRepresentante(scope.$index)"
                      size="mini"
                      type="danger"
                      icon="el-icon-delete"
                      @click="deleteRepresentante(scope.$index)"
                    />
                  </el-button-group>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>

      </cpr-form>
      <modal-representante-favorecido
        :show-modal="showModalRepresentanteFavorecido"
        :close-function="handleModalRepresentanteFavorecido"
        :request-favorecido="false"
        :representante-favorecido="form.representanteData"
        :index="indexRepresentante"
        @add="addNewRepresentative"
        @update="atualizarRepresentante"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.required {
  color: #dd4f43;
}

.group-input {
  width: 100%;

  > :nth-child(n) {
    margin-bottom: 10px;
  }
}

.address-btn-new {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: flex-start;
}
.address-action {
  display: flex;
  width: 100%;
  margin-top: 20px;
  justify-content: space-between;
}
.address-action-save {
  display: flex;
  color: $--color-gray-1;
  margin-top: auto;
  font-weight: bold;
  line-height: 20px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  font-family: $font_primary;
  background-color: $--color-gray-7;
}
.address {
  &__form {
    width: 100%;
    padding: 24px;
    margin-top: 15px;
    margin-bottom: 5px;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: $--color-gray-1;
  }
}

.create-favored {
  &__head {
    top: 112px;
    display: flex;
    position: fixed;
    flex-direction: column;

    a {
      color: $--color-gray-6;
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
      }
    }

    h2 {
      color: $--color-gray-7;
      margin-top: 4px;
    }
  }

  &__body {
    display: flex;
    margin-top: 36px;
  }

  &__steps {
    top: 212px;
    width: fit-content;
    padding: 32px;
    position: fixed;
    box-shadow: $--box-shadow-base;
    border-radius: 4px;
    background-color: $--color-gray-1;

    &-item {
      color: $--color-gray-6;
      cursor: pointer;
      display: flex;
      align-items: center;
      transition: all 0.4s ease-in-out;

      &:hover {
        color: $--color-gray-7;
      }

      &:not(:first-child) {
        margin-top: 12px;
      }

      &::before {
        width: 4px;
        height: 4px;
        content: '';
        display: flex;
        margin-right: 8px;
        border-radius: 4px;
        background-color: transparent;
      }

      &.router-link-active {
        color: $--color-primary;

        &::before {
          background-color: $--color-primary;
        }
      }
    }
  }

  &__form {
    width: 100%;
    margin: 100px 0 0 280px;

    &-type {
      display: flex;
      margin-top: 40px;
      align-items: center;

      p {
        color: $--color-gray-6;
      }

      .el-radio-group {
        margin-left: 30px;
      }
    }

    &-group {
      margin-top: 28px;

      .upload {
        margin-top: 28px;
      }
    }

    &-item {
      &:not(:first-child) {
        margin-top: 28px;
      }
    }

    &-actions {
      display: flex;
      border-top: 1px solid $--color-gray-5;
      margin-top: 32px;
      padding-top: 32px;
      align-items: center;
      justify-content: flex-end;

      .el-button {
        margin-left: 24px !important;
      }
    }
  }
}
</style>
