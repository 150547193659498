<template>
  <Transition
    :enter-class="configTransition.enterClass"
    :enter-active-class="configTransition.enterActive"
    :leave-to-class="configTransition.leaveClass"
    :leave-active-class="configTransition.leaveActive"
    mode="out-in"
  >
    <slot/>
  </Transition>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

type Transition = 'opacity' | 'scale' | 'translateX'

@Component({
  name:'cpr-transition',
})
export default class CprTransition extends Vue {
  @Prop({ default: 'opacity' }) type!:Transition;
  @Prop() enter!:string;

  get configTransition() {
    return {
      leaveClass:  `transition-${this.type}__leave`,
      leaveActive: `transition-${this.type}__leave-active`,
      enterClass:  `transition-${this.type}__enter${this.enter}`,
      enterActive: `transition-${this.type}__enter-active`
    }
  }
  
}
</script>

<style lang="scss" scoped>

.transition {
  &-opacity {
    &__leave-active {
      transition: opacity 0.7s, transform 0.7s; 
    }
    
    &__enter-active {
      transition: opacity 0.7s, transform 0.7s;
    }
  
    &__leave {
      opacity: 0;
    }
  
    &__enter{
      overflow: hidden;
      opacity: 1; 
    }

  }

  &-scale {
    &__leave-active,
    &__enter-active {
      transition: transform 0.7s;
    }
  
    &__leave {
      transform: scale(0.2);
    }

    &__enter {
      overflow: hidden;
      transform: scale(1);
    }
  }

  &-translateX {
    &__leave-active {
      transition: transform 0.1s;
    }

    &__enter-active {
      transition: transform 0.2s;
    }
  
    &__leave {
      transform: translateX(-100%);
    }

    &__enter {
      transform: translateX(100%);
    }
  }

  &-translateY {
    &__leave-active {
      transition: transform 0.1s;
    }

    &__enter-active {
      transition: transform 0.2s;
    }
  
    &__leave {
      transform: translateY(-100%);
    }

    &__enter {
      transform: translateY(100%);

      &-translate_negative{
        transform: translateY(-100%);
      }
    }


  }
  
}

</style>