
<template>
<div id="auth">
  <section ref="image" class="image" />

  <section class="form">
    <div class="form__container">
      <transition name="fade" mode="out-in">
        <router-view class="form__inner"/>
      </transition>
    </div>
    <div class="bottom-info">
      <p>Copyright © 2021 - {{ anoAtual }} Aliare Participações S/A.</p>
      <p>Todos os direitos reservados.</p>
    </div>
  </section>
</div>
</template>

<script lang="ts">
import { Component, Ref, Vue } from 'vue-property-decorator';
import CprIcon from '~/components/CprIcon.vue';
import apiAliare from '~/config/apiAliare';
import { VisualDataResponse } from '~/models/aliare/api'

@Component({
  name: 'page-auth',
  components: {
    CprIcon
  },
})

export default class PageAuth extends Vue {

  @Ref() image!:HTMLElement 
  imagePublicidade = '' 

  get breadcrumb(){
    return this.$route?.meta?.breadcrumb ?? '';
  }

  get anoAtual () {
    const dataAtual = new Date();
    return dataAtual.getFullYear();
  }

  async created () {
    
    const headers = {
      'X-Tenant': process.env.VUE_APP_XTENANT_DEFAULT as string,
      'version': '2'
    };

    try {
      const result = await apiAliare.get<VisualDataResponse>(`v2/Solution/${process.env.VUE_APP_CLIENTID}/VisualData`, {
        headers
      }).then(({ data }) => data)

      this.imagePublicidade = result.solutionVisualIdentity.loginScreenMedia
      this.image.style.background = `url(${this.imagePublicidade})`;
      
    } catch (error) {
      console.log('error', error);
    }
  }

  mounted () {
    this.image.style.background = `url(${this.imagePublicidade})`;
  }

}
</script>

<style lang="scss" scoped>
@import '../styles/mixings/_screen.scss';

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.25s, transform 0.25s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active no <2.1.8 */ {
  opacity: 0;
  overflow: hidden;
  transform: translateX(15px); /* Deslocamento horizontal durante a transição */
}

#auth {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  overflow: hidden;
  background: #FFF;

  section {
    height: 100vh;

    &.image {
      background-color: get-color(primary, pure);
      min-width: 50%;
      background-size: cover !important;
      background-repeat: no-repeat !important;

      @include respond-to(mobile) {
        display: none;
      }
    }
  
    &.form {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      overflow-y: auto;
    
      .form__container {
        padding: 12px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        > .form__inner {
          width: 364px;
        }

        @include respond-to(desktop) {
          padding: 24px;
        }
      }
    }
  }

  .bottom-info {
    height: 60px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px 16px 0 0;
    background: #f1f3f3;
    padding: 24px;
    font-size: 13px;
    color: #656666;

    @include respond-to(desktop) {
      height: 102px;
    }
  }
}
</style>
