<template>
  <div>
    <el-form
      :ref="refForm"
      :model="formModel"
      :status-icon="iconCheck"
      :disabled="disabled"
      v-bind="$attrs"
    >
      <slot></slot>
      <div class="form-actions" style="margin-bottom: 0px">
        <span class="form-actions__info-footer">{{ infoFooter }}</span>
        <span class="pipe" v-if="!!infoFooter"/>
        <el-button 
          v-if="showCancel" 
          class="btn btn-secondary" 
          @click="backForm"
        >
          {{ nameCancel }}
        </el-button>
        <el-button 
          :class="['btn btn-primary ', 'form-actions__submit']"
          @click.prevent="validateForm(submit)"
          :loading="loading || loadSubmit"
          :disabled="disabledSubmit"
        >
          {{ nameSubmit }}
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script lang="ts">
/** Componente baseado na lib abaixo.
*  @link https://element.eleme.io/#/en-US/component/form#form-item-slot
*  Caso necessário, acessar para criar mais validações
*  @link ../utils/validationForm;
*/
import { Component, Mixins, Prop } from 'vue-property-decorator';
import CprFormValidate from '~/mixins/validate-form';

//el-form-item utilizar para validar as entradas 
//função $validationForm na sua propriedade :rules="$validationForm(['name'])"

@Component
export default class CprForm extends Mixins(CprFormValidate) {
  @Prop({default: true }) showCancel!:boolean;
  @Prop({default: false }) disabledSubmit!:boolean;
  @Prop({default: '' }) infoFooter!:boolean;
}
</script>

<style lang="scss" scoped>
@import '../../styles/mixings/_screen.scss';
@import '../../styles/mixings/_fonte-config.scss';
.form-actions{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
  gap: 24px;

  @include respond-to(mobile) {
    flex-direction: column;
    gap: 12px;
  }

  .pipe {
    border-right: 1px solid get-color(neutral, high-dark); 
    height: 25px;
    @include respond-to(mobile) {
      display: none;
    }
  }

  &__info-footer {
    align-self: center;
    text-align: right;
    @include font-config;
    
  }

  &__submit, &__info-footer {
    flex-grow: 0.2;
    @include respond-to(mobile) {
      flex-grow: 1;
      width: 100%;
      text-align: left;
    }
  }
}
</style>