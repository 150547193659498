import { IGarantiaArquivoModel } from '~/models/garantiaArquivo';
import { IFile } from '~/types/file';
import { IUpload } from '~/types/upload';


export const mapperFile = <T extends IUpload>(arquivo:T, id?:string | number):IFile => {
  return {
    id: id,
    name: arquivo.nomeArquivo,
    url: arquivo?.urlDocumento ?? '',
    size: arquivo.tamanhoArquivo ?? 1,
    type: arquivo.extensao,
  }
}

export const mapperGarantiaArquivos = (garantiaArquivos:IFile[]):IGarantiaArquivoModel[] => {
  return garantiaArquivos.map((a):IGarantiaArquivoModel => ({
    garantiaArquivoId: a?.id as number,
    documentoBase64: a?.base64String ?? '',
    urlDocumento: a.url,
    tamanhoArquivo: a.size,
    extensao: a.type,
    nomeArquivo: a.name,
  }))
}
