<template>
  <div class="modal" :class="{ 'is-show': controlerShowModal }" @click.self="handlerOutsideClick" v-if="!destroyModal">
    <div class="modal__wrapper" :style="`width:${width}`" :class="`direction_${showDirection}`" v-loading="loading">
      <div class="modal__wrapper-head">
        <cpr-icon  iconName="icon-close" :noStroke="true" @click="closeFunction" v-if="closeButton"/>
        <span class="p1" v-if="title">{{ title }}</span>
        <slot name="head">
        </slot>
      </div>
      <div class="modal__wrapper-body">
        <slot name="body"></slot>
      </div>
      <div  class="modal__wrapper-footer" v-if="isFooter">
        <slot name="footer"></slot>
      </div>
    </div> 
  </div>
</template>

<script lang="ts">
import {Vue,Component, Prop, Watch} from 'vue-property-decorator';
import CprIcon from './CprIcon.vue';

type showDirection = 'top' | 'bottom' | 'right';

@Component({
  components: {
    CprIcon
  },
})
export default class CprModal extends Vue {
  @Prop({default: false, required: false, type: Boolean,}) show!:boolean;
  @Prop({default: false, required: false, type: Boolean,}) loading!:boolean;
  @Prop({default: false, required: false, type: Boolean,}) isFooter!:boolean;
  @Prop({default: true, required: false, type: Boolean,}) closeButton!:boolean;
  @Prop({default: true, required: false, type: Boolean,}) outsideClick!:boolean;
  @Prop({default: false, required: false, type: Function,}) closeFunction!:() => void;
  @Prop({default: '', required: false, type: String,}) width!:string;
  @Prop({default: '', required: false, type: String,}) title!:string;
  @Prop({default: 'top', required: false, type: String,}) showDirection!:showDirection;

  handlerOutsideClick(){
    if(this.outsideClick) this.closeFunction();
  }

  destroyModal = true;
  controlerShowModal = false;

  beforeDestroy(){
    this.handleHiddenOverflow(false)
  }

  handleHiddenOverflow(hidden:boolean){
    hidden 
    ? document.querySelector('body')?.classList.add('overflow__hidden')
    : document.querySelector('body')?.classList.remove('overflow__hidden')
  }

  @Watch('show')
  onShow(value:boolean){
    if(value) {
      this['destroyModal'] = false;
      
      setTimeout(() => {
        this.handleHiddenOverflow(true)
        this.controlerShowModal = value;
      }, 150);
      
    } else {
      this.controlerShowModal = value
      
      setTimeout(() => {
        this.handleHiddenOverflow(false)
        this['destroyModal'] = true
      }, 300);
    }
  }
}
</script>
  
<style lang="scss" scoped>
@import '../styles//mixings/_screen';

.modal {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 2000;  
  opacity: 0;
  position: fixed;
  transition: all 0.3s ease-in-out;
  align-items: center;
  pointer-events: none;
  justify-content: center;
  backdrop-filter: blur(10px);
  background-color: rgba(get-color(neutral, ultra-dark), 0.35);

  &__body {
    margin-top: 16px;

    :nth-child(1) {
      margin-bottom: 8px;
    }
  }

  &__wrapper {
    position: relative;
    transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1);
    border-radius: 8px;
    background-color: $--color-gray-1;
    display: grid;
    width: 75vw;

    @include respond-to(mobile){
      width: 100%;
    }
  
    @include respond-to(desktop){
      width: 60vw;
    }

    &.direction{
      &_top {
        top:-12%
      }

      &_right {
        right: -12%;
      }

      &_bottom {
        bottom: -12%;
      }
    }

    &-head {
      height: 45px;
      border-bottom: 1px solid #d9d9d9;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      font-size: 16px;
      font-weight: bold;
      color: get-color(fonte, text-medium);
      font-family: $font_secondary;
      box-shadow: 0px 1px 3px 0px #d9d9d9;
      border-radius: 8px 8px 0 0;
      z-index: 2500;
      position: relative;

      .p1 {
        font-size: 18px;
        line-height: normal;
      }
      
      > ::v-deep {
        svg {
          position: absolute;
          display: flex;
          justify-content: flex-end;
          top: 12px;
          right: 8px;
          cursor: pointer;
          z-index: 1;
          path{

            fill: $--color-gray-5;
          }

          &:hover path {
            fill: $--color-primary;
          }

          @include respond-to(mobile) {
            right: 2px;
          }
          
        }
      }
    }
  
    &-body {
      width: 100%;
      color: get-color(fonte, text-medium);
      font-size: 14px;
      word-wrap: break-word;
      overflow-wrap: break-word;
      text-align: justify;
      overflow-y: auto;
      max-height: 80vh;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-footer {  
      height: 70px;
      border-top: 1px solid #d9d9d9;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      box-shadow: 0px -2px 3px 0px #d9d9d9;
      border-radius: 8px;
      z-index: 2500;
      position: relative;
      gap: 20px,
    }

    &-head, &-body, &-footer {
      padding: 10px 36px;

      @include respond-to(mobile) {
        padding: 10px 24px;
      }

    }
  }

  &.is-show {
    opacity: 1;
    pointer-events: all;
  }

  &.is-show &__wrapper {
    &.direction{
      &_top {
        top:0
      }

      &_right {
        right: 0;
      }

      &_bottom {
        bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .modal{
    padding-top: 2rem;
    
    &__wrapper-head {
      padding: 10px 20px;
      flex-direction: column-reverse;
      align-items: flex-start;
      height: 80px;
      & > :nth-child(n){
        width: 100%;
        height: 50%;
      }

      & > :nth-child(1){
        padding-top: 10px;
      }

      & > :nth-child(2){
        border-bottom: 1px solid #e1e1e1;
        padding-bottom: 10px;
      }   
    }

    &__wrapper-body{
      max-height: 79vh;
      width: 100vw;
      padding: 10px 20px;
    }
  }
}
</style>
  