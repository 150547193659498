/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { IFormacaoLavouraModel } from '~/models/lavoura';
import { IEstadoModel, IMunicipioModel } from '~/models/locais';
import { IApiOKResult } from '~/types/api';
import baseService from '../baseServise';

class ServiceFormacaoLavoura extends baseService<IFormacaoLavouraModel> {
  constructor() {
    super('FormacaoLavoura');
  }

  async obterEstados():Promise<Array<IEstadoModel>> {
    return (await axios.get(`${this.baseURL}/ObterEstados`)).data;
  }

  async obterMuniciosPorUfId(estadoId:number):Promise<Array<IMunicipioModel>> {
    return (await axios.get(`${this.baseURL}/ObterMunicipiosPorUF?ufEstadoId=${estadoId}`)).data;
  }

  async obterFormacoesPorTituloId(tituloId:string):Promise<IApiOKResult<Array<IFormacaoLavouraModel>>> {
    return (await axios.get(`${this.baseURL}?tituloId=${tituloId}`)).data;
  }

}

export default new ServiceFormacaoLavoura();