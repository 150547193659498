import axios from 'axios';
import { IFavorecidoTituloModel } from '~/models/favorecido';
import { IFavorecidoModel } from '~/types/titulo';
import baseService from '../baseServise';

class ServiceFavorecido extends baseService<IFavorecidoModel> {
  constructor() {
    super('Favorecido')
  }

  async obterTodosFavorecidos() : Promise<Array<IFavorecidoTituloModel>> {
    return (await axios.get(`${this.baseURL}/ObterTodosFavorecidos`)).data;
  }

}

export default new ServiceFavorecido();
