class WebStorage {
  private localStorage:Storage;
  
  constructor() {
    this.localStorage = window.localStorage;
  }
  
  getItem<T>(key:string):T {
    const content = this.localStorage.getItem(key);
    return content ? JSON.parse(content) : null;
  }

  setItem<T>(key:string, content:T) {
    this.localStorage.setItem(key, JSON.stringify(content));
  }

  removeItem(key:string) {
    this.localStorage.removeItem(key);
  }
  

  clean() {
    this.localStorage.clear()
  }
}
  
export default new WebStorage();
  
