<template>
  <div class="create-harvest">
    <div class="create-harvest__head">
      <RouterLink to="/admin/safras" class="p1">
        <IconArrow />Safras
      </RouterLink>

      <h2>{{ this.Id ? 'Editar' : 'Nova' }} safra</h2>
    </div>

    <div class="create-harvest__body" id="identificacao">
      <form
        class="create-harvest__form"
        @submit.prevent="submit"
        enctype="multipart/form-data"
      >
        <div>
          <cpr-label label="Identificação da safra" />

          <div class="create-harvest__form-group">
            <div class="create-harvest__form-item">
              <int-input
                label="Nome"
                v-model="form.descricao"
                placeholder="0000/0000"
                maxlength="9"
              />
            </div>
            <div class="create-harvest__form-item">
              <int-input
                inputType="textarea"
                v-model="form.observacao"
                label="Observações"
                maxlength="150"
                placeholder="Observações"
              />
            </div>
          </div>
          <div class="create-harvest__form-type">
            <p class="p2">Status:</p>

            <el-radio-group v-model="form.status">
              <el-radio label="A">Ativo</el-radio>
              <el-radio label="I">Inativo</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="create-harvest__form-actions">
          <el-button
            @click.prevent="handleDelete"
            v-if="this.Id"
            type="danger"
            icon="el-icon-delete"
            class="btn btn-secondary is-danger"
          >
            Excluir
          </el-button>
          <el-button
            type="terciary"
            class="btn btn-secondary"
            @click.prevent="back"
          >
            Cancelar
          </el-button>
          <el-button
            type="primary"
            native-type="submit"
            class="btn btn-primary"
          >
            Salvar
          </el-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as service from './services'
import CprLabel from '@/components/Label.vue'
import IntInput from '@/components/Input.vue'
import IconArrow from '@/assets/svgs/icon-arrow-left.svg'

export default {
  name: 'harvest-form',
  components: {
    CprLabel,
    IntInput,
    IconArrow
  },

  data: () => ({
    Id: null,
    form: {
      descricao: '',
      status: 'A',
      observacao: ''
    }
  }),

  methods: {
    back() {
      this.$router.go(-1)
    },

    handleDelete() {
      this.$cprAlert({
        type: 'delete',
        title: 'Excluindo safra!',
        textInfo:
          `Você está excluindo a safra ${this.form.descricao}. Caso confirme, os dados serão ` +
          'excluídos e não poderão ser recuperados. O que você deseja fazer?',
        namePositiveAction: 'Manter Safra',
        nameNegativeAction: 'Excluir Safra',
        negativeAction: () => this.deleteItem()
      })
    },

    async deleteItem() {
      await service.deleteById(this.Id)
      this.back()
    },

    clear(value) {
      /**
       * @todo clear data
       */
      this.form = {}
      this.Id = null
      if (value === 'cancel') {
        this.back()
      }
    },

    async submit() {
      try {
        debugger
        if (!this.validarSafra(this.form.descricao)) {          
          this.$showNotify('error', 'Descrição inválida')
          return       
        }

        await service.save(this.form, this.Id, this.usuario.empresaId)
        this.$showNotify(
          'success',
          `Safra ${this.Id ? 'editada' : 'cadastrada'} com sucesso!`
        )
        this.back()
      } catch (error) {        
        this.$showMessagesErrors(error)
      }
    },

    validarSafra(descricao) {
      if (!descricao.includes('/')) return false
      const descricaoValida = descricao.split('/')
      return descricaoValida.every((el) => el.length === 4)
    }
  },

  computed: {
    ...mapGetters('auth', ['usuario'])
  },
  async mounted() {
    const { id } = this.$route.params
    if (id) {
      const harvest = await service.getById(id)
      this.form = harvest
      this.Id = id
    }
  }
}
</script>

<style lang="scss">
.create-harvest {
  &__head {
    top: 112px;
    display: flex;
    flex-direction: column;

    a {
      color: $--color-gray-6;
      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
      }
    }

    h2 {
      color: $--color-gray-7;
      margin-top: 4px;
    }
  }

  &__body {
    display: flex;
    margin-top: 10px;
  }

  &__form {
    width: 100%;
    margin: 20px 0 0 0;

    &-type {
      display: flex;
      margin-top: 10px;
      align-items: center;

      p {
        color: $--color-gray-6;
      }

      .el-radio-group {
        margin-left: 30px;
      }
    }

    &-group {
      margin-top: 48px;

      .upload {
        margin-top: 28px;
      }
    }

    &-item {
      &.is-multi {
        display: flex;
        align-items: flex-end;

        .group-input {
          width: 100%;

          & > .input:not(:first-child) {
            margin-top: 24px;
          }
        }

        & > .input:not(:first-child) {
          margin-left: 16px;
        }
      }

      &:not(:first-child) {
        margin-top: 28px;
      }
    }

    &-actions {
      display: flex;
      border-top: 1px solid $--color-gray-5;
      margin-top: 32px;
      padding-top: 32px;
      align-items: center;
      justify-content: flex-end;

      .el-button {
        margin-left: 24px !important;
      }
    }
  }
}
</style>
