import { render, staticRenderFns } from "./CprListTable.vue?vue&type=template&id=0cd793b7&scoped=true"
import script from "./CprListTable.vue?vue&type=script&lang=ts"
export * from "./CprListTable.vue?vue&type=script&lang=ts"
import style0 from "./CprListTable.vue?vue&type=style&index=0&id=0cd793b7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cd793b7",
  null
  
)

export default component.exports