/* eslint-disable @typescript-eslint/no-explicit-any */
import type { TipoPessoa } from '~/Enums/pessoa'
import type { IConjugeModel } from './conjuge'
import type { IPessoaModel } from './pessoa'
import type { IRepresentanteModel } from './representante'
import type { IUpload } from '~/types/upload'

/**
* Cliente é um Emitente cadastrado na plataforma
*/
export interface IClienteModel extends IPessoaModel{
  clienteId?: number,
  tipoCliente: TipoPessoa,
  dataNascimento?: Date,
  clienteArquivos?:IClienteArquivo[],
  contratos?: any,
  atasAssembleias?: any
}

export interface IClienteSimplificadoModel {
  nome: string,
  id: string,
  documento: string,
  uf: string,
  cidade: string,
  datacadastro: string
}

export interface IClienteTituloModel {
  clienteTituloId: number,
  clienteId?: number,
  tipoCliente: TipoPessoa,
  nomeFantasia?: string,
  razaoSocial?: string,
  cnpj?: string,
  nome?: string,
  rg?: string,
  cpf?: string,
  estadoCivil: string,
  cep: string,
  filiacao?: string,
  rua?: string,
  numero?: string,
  complemento?: string,
  bairro?: string,
  uf?: string,
  cidade?: string,
  dataCadastro?: Date,
  emailEndereco?: string,
  telefone?: string,
  conjugeId?: number,
  conjuge?: IConjugeModel,
  empresaId?: number,
  naturalidade?: string,
  representanteId?: number,
  representante?: IRepresentanteModel,
  orgaoExpedidorRg?: string,
  emissaoRg?: string,
  nacionalidade: string,
  numInscricaoEstadual?: string,
  profissao?: string,
  dataNascimento?: Date,
}


export interface IInscricaoEstadualModel {
  inscricaoEstadualId?: number,
  numInscricaoEstadual: string,
  clienteId?: number,
  endossatarioId?:number,
}


export interface IClienteArquivo extends IUpload {
  clienteArquivoId?: number;
  tipoClienteArquivo?: TipoClienteArquivo;
  dataCadastro?: Date;
  clienteId?: number;
}

export enum TipoClienteArquivo {
  DOCUMENTOS_PESSOAIS = 1,
  COMPROVANTES_RESIDENCIAS,
}