<template>
  <cpr-modal-form
    :showModal="showModal"
    :outsideClick="false"
    :title="`${
      isEditeRepresentante
        ? 'Editar representante do favorecido'
        : 'Adicionar representante ao favorecido'
    }`"
    :close-function="voltar"
    refForm="formRepresentante"
    :formModel="formRepresentante"
    :submit="handleSubmit"
    :cancel="voltar"
    nameCancel="Cancelar"
    :nameSubmit="`${isEditeRepresentante ? 'Editar' : 'Adicionar'}`"
  >
    <div class="form-grid">
      <el-form-item
        label="Nome"
        prop="nome"
        :rules="$validationForm(['required'])"
      >
        <cpr-input v-model="formRepresentante.nome" />
      </el-form-item>

      <el-form-item
        label="CPF"
        prop="cpf"
        :rules="$validationForm(['required', 'cpf'])"
      >
        <cpr-input
          v-model="formRepresentante.cpf"
          placeholder="000.000.000-00"
          v-mask="['###.###.###-##']"
        />
      </el-form-item>
    </div>

    <div class="form-grid">
      <el-form-item label="RG" prop="rg">
        <cpr-input v-model="formRepresentante.rg" placeholder="0000000" />
      </el-form-item>

      <el-form-item
        label="Email"
        prop="email"
        :rules="$validationForm(['required', 'email'])"
      >
        <cpr-input
          v-model="formRepresentante.email"
          placeholder="exemplo@email.com"
          :disabled="isDisabledEmail"
        />
      </el-form-item>
    </div>

    <div class="form-grid">
      <el-form-item label="Cargo" prop="cargo">
        <cpr-input
          v-model="formRepresentante.cargo"
          placeholder="Gerente Financeiro "
        />
      </el-form-item>

      <cpr-form-input
        label="Telefone"
        prop="telefone"
        :rules="['required']"
        v-model="formRepresentante.telefone"
        placeholder="(DD) XXXXX-XXXX"
        mask="telefone"
      />
    </div>
  </cpr-modal-form>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue, Watch } from 'vue-property-decorator';
import CprInput from '@/components/Input.vue';
import CprIcon from '@/components/CprIcon.vue';
import CprForm from '~/components/cpr-form/cpr-form.vue';
import CprModalForm from '@/components/CprModalForm.vue';
import type { IRepresentanteFavorecidoModel } from '~/models/favorecido';
import ServiceFavorecido from '@/services/Favorecido';
import { IFavorecidoModel } from '~/types/titulo';
import { findModifiedObjects } from '@/utils/methods';

@Component({
  name: 'modal-representante-favorecido',
  components: {
    CprInput,
    CprIcon,
    CprForm,
    CprModalForm
  }
})
export default class ModalRepresentanteFavorecido extends Vue {
  @Prop({ default: false }) showModal!: boolean;
  @Prop({ default: true }) requestFavorecido!: boolean;
  @Prop() closeFunction!: () => void;
  @VModel() favorecidoModel!: IFavorecidoModel;
  formRepresentante = {} as IRepresentanteFavorecidoModel;
  @Prop() representanteFavorecido!: IRepresentanteFavorecidoModel;
  @Prop() index!: number;

  get isDisabledEmail() {
    return typeof this.formRepresentante.representanteFavorecidoId === 'number';
  }

  get isEditeRepresentante() {
    return typeof this.index === 'number';
  }

  reseteForm() {
    this.formRepresentante = {
      nome: '',
      rg: '',
      cpf: '',
      cargo: '',
      email: '',
      telefone: ''
    };
  }

  async enviar() {
    try {
      const payload = { ...this.favorecidoModel };
      if (!payload.representanteFavorecido)
        payload.representanteFavorecido = [];

      payload.representanteFavorecido.push({ ...this.formRepresentante });

      const { representanteFavorecido } = (
        await ServiceFavorecido.save(payload, payload.favorecidoId)
      ).data;
      this.favorecidoModel.representanteFavorecido = representanteFavorecido;

      const representeAdicionado = findModifiedObjects(
        payload.representanteFavorecido,
        representanteFavorecido
      );

      this.$showNotify(
        'success',
        `Representante adicionado ao favorecido ${this.favorecidoModel.razaoSocial}!`
      );
      this.$emit(
        'add',
        representeAdicionado.find((r) => r)
      );
      this.voltar();
    } catch (error) {
      this.$showMessagesErrors(error);
    }
  }

  handleRepresentanteFavorecido() {
    this.isEditeRepresentante
      ? this.$emit('update', {
          index: this.index,
          representanteFavorecido: this.formRepresentante
        })
      : this.$emit('add', this.formRepresentante);

    this.voltar();
  }

  handleSubmit() {
    this.requestFavorecido
      ? this.enviar()
      : this.handleRepresentanteFavorecido();
  }

  voltar() {
    this.reseteForm();
    this.closeFunction();
  }

  @Watch('showModal')
  onShowModal(value: boolean) {
    if (value && this.isEditeRepresentante) {
      this.formRepresentante = { ...this.representanteFavorecido };
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-collapse-item__header {
  min-height: 48px;
  padding: 5px 20px;
  line-height: normal;
  height: auto;
  font-size: larger;
  color: $--color-gray-7;
  background-color: transparent;
  border-bottom: 1px solid #8f9bb39e;

  &.is-active {
    border-bottom-color: transparent !important;
  }

  &--icon {
    margin-right: 1rem;
  }
}

::v-deep .el-collapse {
  border-top: 1px solid #8f9bb39e;
  border-bottom: 1px solid #8f9bb39e;
}

::v-deep .el-collapse-item__content {
  border: 1px solid $--color-primary;
  padding: 25px;
}

::v-deep .el-upload {
  width: 100%;

  &:focus {
    border-color: $--color-primary;
    color: $--color-primary;

    & .el-upload-dragger {
      border-color: $--color-primary;
    }
  }

  &-dragger {
    width: 100%;

    & .el-upload__text em {
      color: $--color-primary;
    }

    &:hover {
      border-color: $--color-primary;
    }

    &.is-dragover {
      border: 2px dashed $--color-primary;
    }
  }
}

.container {
  &--collapse {
    width: 100%;
    max-width: 460px;
    margin-left: 1rem;
  }
}
</style>
