<template>
  <div class="notification-body" 
  >
    <strong v-if="hasTitulo">{{ titulo }}</strong>
    <ul v-html="message" />
    <!-- <cpr-progress class="progress" :percentual="percentual" :transition="{ time: 0.1, tipo: 'ease-out'}"/>  -->
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
//import CprProgress from './cpr-progress/cpr-progress.vue';

@Component({
  name:'Notification',
  components: {
    //CprProgress
  }
})
export default class Notification extends Vue{  
  @Prop({default: '', required: true}) titulo!:string | undefined;
  @Prop({default: '', required: true}) message!:string;

  get hasTitulo() {
    return !!this.titulo;
  }

  // percentual = 100;
  // timer: ReturnType<typeof setTimeout> | null = null; // Declaração da propriedade timer
  // isVisible = true; // Flag para controlar a visibilidade da notificação

  // mounted() {
  //       //Adicionar os eventos abaixo na div notification-body
  //       //@mouseenter="pausarAtualizacao" 
  //       //@mouseleave="retomarAtualizacao"
  //   //------------------------------------------------------------
  //   window.addEventListener('focus', this.onWindowFocus);
  //   window.addEventListener('blur', this.clearTimer);

  //   if (document.hasFocus()) {
  //     this.atualizarPercentual();
  //   }
  // }

  // beforeDestroy() {
  //   window.removeEventListener('focus', this.onWindowFocus);
  //   window.removeEventListener('blur', this.clearTimer);
  // }

  // onWindowFocus() {
  //   if (this.isVisible) {
  //     this.atualizarPercentual();
  //   }
  // }

  // atualizarPercentual() {
  //   const duration = 5000; 
  //   const decrement = 1; 
  //   const interval = duration / 100;

  //   const update = () => {
  //     if (this.percentual > 0) {
  //       this.percentual -= decrement;
  //       this.timer = setTimeout(update, interval);
  //     }
  //   };
  //   update();
  // }

  // pausarAtualizacao() {
  //   this.clearTimer();
  // }

  // retomarAtualizacao() {
  //   if (this.isVisible) {
  //     this.atualizarPercentual();
  //   }
  // }

  // clearTimer() {
  //   if (this.timer) {
  //     clearTimeout(this.timer);
  //     this.timer = null;
  //   }
  // }
}
</script>

<style lang="scss" scoped>
.notification-body {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  row-gap: 8px;

  .progress {
    margin-top: 15px
  }
}
</style> 