/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import ServiceParametrizacaoEmpresa from '~/services/ParametrizacaoEmpresa' 
import { IConfiguracaoAprovacao, IParametrizacaoEmpresaModel } from '~/models/parametrizacaoEmpresa';
import webStorage from '~/common/webStorage';
import { AprovacaoParametrizacao, LocalParametrizacao, TipoProcessoAprovacao } from '~/Enums/aprovacaoParametrizacao';

export interface ParametrizacaoState {
  configuracaoAprovacao: IConfiguracaoAprovacao
}

const state: ParametrizacaoState = {
  configuracaoAprovacao: webStorage.getItem<IConfiguracaoAprovacao>('configuracaoAprovacaoVigente') || {} as IConfiguracaoAprovacao,
};

const getters: GetterTree<ParametrizacaoState, any> = {
  obterConfiguracaoAprovacaoTitulo: (state) => state.configuracaoAprovacao.titulo,
  obterConfiguracaoAprovacaoEndosso: (state) =>  handleAprovacaoEndosso(state.configuracaoAprovacao),
  obterConfiguracaoAprovacaoVigente: (state) => ({ ...state.configuracaoAprovacao }),
  hasAprovacaoEndosso: (state) => hasAprovacaoEndosso(state.configuracaoAprovacao),
  ehUnificado: (state) => isUnificado(state.configuracaoAprovacao),
};

const mutations: MutationTree<ParametrizacaoState> = {
  setConfiguracoes(state, parametrizacao:IConfiguracaoAprovacao) {
    if(parametrizacao) {
      state.configuracaoAprovacao = parametrizacao;
      webStorage.setItem('configuracaoAprovacaoVigente', parametrizacao)
    }
  },
};

const actions: ActionTree<ParametrizacaoState, any> = {
  async parametrizar({ commit }, parametrizacao:IConfiguracaoAprovacao) {
    const { endosso, titulo, tipoProcessoAprovacao } = parametrizacao;
    const config = { tipoProcessoAprovacao, titulo: {}, endosso: {} }

    try {
      const { parametrizacaoFluxoAprovacao } = titulo;
      const payload = { 
        parametrizacaoFluxoAprovacao, 
        tipoParametrizacao: isUnificado(parametrizacao) ? LocalParametrizacao.TODAS : LocalParametrizacao.MINUTA, 
        tipoProcessoAprovacao 
      }

      config.titulo = (await ServiceParametrizacaoEmpresa.save(payload)).data;

      if(!isUnificado(parametrizacao)) {
        const { parametrizacaoFluxoAprovacao } = endosso;
        const payloadEndosso = { 
          parametrizacaoFluxoAprovacao, 
          tipoParametrizacao: LocalParametrizacao.ENDOSSO, 
          tipoProcessoAprovacao 
        }
        config.endosso = (await ServiceParametrizacaoEmpresa.save(payloadEndosso)).data;
      }

      commit('setConfiguracoes', config);
      
    } catch (err: any) {
      throw err;
    }
  },
  async buscarVigente({ commit }) {
    try {
      
      const config:IConfiguracaoAprovacao = { 
        tipoProcessoAprovacao: TipoProcessoAprovacao.UNIFICADO, 
        titulo: {} as IParametrizacaoEmpresaModel, 
        endosso: {} as IParametrizacaoEmpresaModel,  
      }

      const parametrizacaoList = (await ServiceParametrizacaoEmpresa.getAll()).data
      parametrizacaoList.forEach(p => {
        if(p.tipoParametrizacao === LocalParametrizacao.ENDOSSO) {
          config.endosso = p
        } else {
          config.titulo = p
        } 
      })

      config.tipoProcessoAprovacao = config.titulo?.tipoProcessoAprovacao ?? TipoProcessoAprovacao.UNIFICADO
      
      if(isUnificado(config)){
        config.endosso = {  
          parametrizacaoFluxoAprovacao: AprovacaoParametrizacao.COM_APROVACAO,
          tipoParametrizacao: LocalParametrizacao.ENDOSSO,
          tipoProcessoAprovacao: TipoProcessoAprovacao.UNIFICADO   
        }
      }

      commit('setConfiguracoes', config);

    } catch (err: any) {
      throw err;
    }
  },
};

const isUnificado = (configuracaoAprovacao: IConfiguracaoAprovacao) => {
  return configuracaoAprovacao.tipoProcessoAprovacao === TipoProcessoAprovacao.UNIFICADO
}

const hasAprovacaoEndosso = (configuracaoAprovacao: IConfiguracaoAprovacao) => {
  const {tipoProcessoAprovacao, endosso , titulo } =  configuracaoAprovacao

  if(tipoProcessoAprovacao === TipoProcessoAprovacao.UNIFICADO) {
    return titulo.parametrizacaoFluxoAprovacao === AprovacaoParametrizacao.COM_APROVACAO
  }

  return endosso.parametrizacaoFluxoAprovacao === AprovacaoParametrizacao.COM_APROVACAO
}

const handleAprovacaoEndosso = (configuracaoAprovacao: IConfiguracaoAprovacao) => {
  const {tipoProcessoAprovacao, endosso , titulo } =  configuracaoAprovacao

  if(tipoProcessoAprovacao === TipoProcessoAprovacao.UNIFICADO) {
    return titulo
  }
  return endosso
}


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
