import usuariosRoute from './usuarios/index.routes';
import minhasMinutasRoute from './minhas-minutas/index.routes';
import configuracoes from './configuracoes/index.routes'
import Blank from '~/layouts/blank.vue';
import HarvestForm from '~/views/App/Harvest/Form.vue';
import HarvestList from '~/views/App/Harvest/List.vue';
import ProductList from '~/views/App/Product/List.vue';

const minutaEmpresa = {
  path: 'minutas-empresa',
  component: Blank,
  children: [
    {
      path: '/minutas-empresa/:id?',
      component: ()=> import('./minhas-minutas/minutas-empresa/index.vue'),
      name: 'MinutaEmpresa',
      meta: {
        requiresAuth: true,
      },
    },
  ],
};

const product = {
  path: 'produtos',
  component: Blank,
  children: [
    {
      path: '',
      component: ProductList,
      name: 'Product',
      meta: {
        requiresAuth: true,
        routeAdmin: true,
        breadcrumb: 'Culturas'
      },
    },
  ],
};

const harvest = {
  path: 'safras',
  component: Blank,
  children: [
    {
      path: '',
      name: 'Harvest',
      component: HarvestList,
      meta: {
        requiresAuth: true,
        routeAdmin: true,
        breadcrumb: 'Safras'
      },
    },
    {
      path: '/safras/novo',
      component: HarvestForm,
      name: 'NewHarvest',
      meta: {
        requiresAuth: true,
        routeAdmin: true,
      },
    },
    {
      path: '/safras/editar/:id?',
      component: HarvestForm,
      name: 'EditHarvest',
      meta: {
        requiresAuth: true,
        routeAdmin: true,
      },
    },
  ],
};

const customFields = {
  path: 'campos-personalizados',
  component: Blank,
  children: [
    {
      path: '',
      component: () => import('~/views/admin/campos-personalizados/campos-personalizados.vue'),
      name: 'CustomFields',
      meta: {
        requiresAuth: true,
        routeAdmin: true,
        breadcrumb: 'Campos Personalizados'
      },
    }
  ]
}

export default {
  path: '/admin',
  component: Blank,
  children: [
    {
      path: '',
      component: () => import('./admin.vue'),
      name: 'admin',
      meta: {
        requiresAuth: true,
        routeAdmin: true,
        breadcrumb: 'Gestão'
      },
    },
    configuracoes,
    customFields,
    harvest,
    product,
    usuariosRoute,
    minhasMinutasRoute,
    minutaEmpresa
  ],
};

