import Vue from 'vue';
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import './toastification-override.scss';

const options = {
  position: POSITION.BOTTOM_CENTER,
  transition: 'Vue-Toastification__fade',
  maxToasts: 5,
  newestOnTop: true
}

Vue.use(Toast, options);

/**@todo configuração para novo toast */
// const options = {
//   position: POSITION.BOTTOM_CENTER,
//   transition: 'Vue-Toastification__fade',
//   maxToasts: 5,
//   timeout: 5000,
//   hideProgressBar: true, 
//   newestOnTop: true,
//   closeOnClick: true,
//   pauseOnFocusLoss: false,
//   pauseOnHover: true,
//   draggable: true,
//   draggablePercent: 0.6,
//   showCloseButtonOnHover: true,
//   closeButton: false,
//   icon: true,
//   rtl: false
// } 