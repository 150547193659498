import Blank from '~/layouts/blank.vue';

export default {
  path: 'usuarios',
  component: Blank,
  children: [
    {
      path: '',
      component: () => import('~/views/admin/usuarios/usuario.vue'),
      name: 'Usuarios',
      meta: {
        requiresAuth: true,
        routeAdmin: true,
        breadcrumb: 'Usuários'
      },
    }
  ],
};
