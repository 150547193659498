import Blank from '~/layouts/blank.vue';

export default {
  path: 'minhas-minutas',
  component: Blank,
  children: [
    {
      path: '',
      component: () => import('~/views/admin/minhas-minutas/index.vue'),
      name: 'MinhasMinutas',
      meta: {
        requiresAuth: true,
        routeAdmin: true,
        breadcrumb: 'Minhas Minutas'
      },
    }
  ],
};
