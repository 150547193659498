<template>
  <div class="container-locais">
    <div class="container-locais__head">
      <RouterLink to="/locais-entrega" class="p1">
        <i class="el-icon-arrow-left" />
        Locais de Entrega
      </RouterLink>

      <h2>{{localEntregaId ? 'Editar'  : 'Novo'}} Local de Entrega</h2>
    </div>
    <div class="container-locais__body">
      <ul class="container-locais__steps">
        <RouterLink
          class="container-locais__steps-item p1"
          v-for="item in steps"
          :key="item.path"
          :to="`#${item.path}`"
        > 
          {{item.label}}
        </RouterLink>
      </ul>

      <cpr-form
        class="container-locais__form"
        :formModel="formLocalEntrega"
        :submit="submitForm"
        :cancel="back"
      >
          <cpr-label label="Armazém" id="armazem" />
          <div class="is-multi">
            <el-form-item prop="nome" label="Nome"
              :rules="$validationForm(['required'])"
              maxlength="35"
            >
              <cpr-input v-model="formLocalEntrega.nome" />
            </el-form-item>
            <el-form-item prop="cnpj" label="CNPJ">
              <input-document v-model="formLocalEntrega.cnpj" inputType="cnpj"/>
            </el-form-item>
          </div>
          <cpr-label label="Localização" id="localizacao" style="margin-top: 15px"/>
          <div class="is-multi">
            <el-form-item prop="cep" label="CEP"
              :rules="$validationForm(['required'])"
            >
              <cpr-input
                v-model.lazy="formLocalEntrega.cep"
                placeholder="Digite o CEP para realizarmos a busca do endereço"
                @change="(cep) => searchEndereco(cep)"
                :suffix-icon="`${ loadCep ? 'el-icon-loading' : '' }`"
                v-mask="'#####-###'"
              />
            </el-form-item>
            
            <el-form-item prop="uf" label="Estado"
              :rules="$validationForm(['required'])"
            >
              <cpr-input 
                v-model="formLocalEntrega.uf"
                maxlength="2"
                placeholder="UF"
                v-uppercase
              />
            </el-form-item>
            <el-form-item prop="municipio" label="Municipio"
              :rules="$validationForm(['required'])"
            >
              <cpr-input v-model="formLocalEntrega.municipio" />
            </el-form-item>
          </div>
          
          <el-form-item prop="endereco" label="Endereço"
            :rules="$validationForm(['required'])"
          >
            <cpr-input
              v-model="formLocalEntrega.endereco"
              placeholder="Defina o endereço do local de entrega"
            />
          </el-form-item>
      </cpr-form>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import CprForm from '../../../components/cpr-form/cpr-form.vue';
import Input from '../../../components/Input.vue';
import InputDocument from '../../../components/InputDocument.vue';
import Label from '../../../components/Label.vue';
import { ILocalEntregaModel } from '~/models/locais';
import ServiceLocalEntrega from '../../../services/LocalEntrega';
import ServiceCliente from '../../../services/Cliente';

@Component({
  components: {
    CprForm,
    'input-document': InputDocument,
    'cpr-input': Input,
    'cpr-label': Label,
  },
})
export default class FormLocaisEntrega extends Vue {
  localEntregaId!:string
  formLocalEntrega = {} as ILocalEntregaModel;
  loadCep = false;

  get steps() {
    return [
      {
        path: 'armazem',
        label: 'Armazém',
      },
      {
        path: 'localizacao',
        label: 'Localização',
      },
    ];
  }


  created(){
    this.localEntregaId = this.$route.params.id;
  }

  async mounted() {
    if(this.localEntregaId){
      try {
        this.formLocalEntrega = (await ServiceLocalEntrega.getById(this.localEntregaId)).data;
      } catch (error) {
        this.$showMessagesErrors(error);
      }
    }
  }

  async submitForm(){
    try {
      const payload:ILocalEntregaModel = {
        ...this.formLocalEntrega,
        cep: this.formLocalEntrega.cep.replace('-', '')
      };
      await ServiceLocalEntrega.save(payload, this.localEntregaId);
      this.$showNotify('success', `Local de entrega ${this.localEntregaId ? 'editado' : 'cadastrado'} com sucesso!`);
      this.back();
    } catch (error) {
      this.$showMessagesErrors(error, 'warning');
    }
  }

  async searchEndereco(cep:string) {
    try {
      this.loadCep = !this.loadCep;
      const getCep = await ServiceCliente.buscaCep(cep)
      this.formLocalEntrega = {
        ...this.formLocalEntrega,
        endereco: getCep.logradouro,
        municipio: getCep.localidade,
        uf: getCep.uf
      }
    } catch (error) {
      this.$showMessagesErrors(error);
    } finally {
      this.loadCep = !this.loadCep;
    }
  }

  back() {
    this.$router.push('/locais-entrega');
  }

}
</script>

<style lang="scss" scoped>
.container {
  &-locais {
    &__head {
      top: 112px;
      display: flex;
      flex-direction: column;
      position: fixed;
      align-items: center;

      a {
        color: $--color-gray-6;
        display: flex;
        align-items: center;
      }

      h2 {
        color: $--color-gray-7;
        margin-top: 4px;
      }
    }

    &__body {
      display: flex;
      margin-top: 36px;
    }

    &__steps {
      top: 212px;
      width: fit-content;
      padding: 32px;
      position: fixed;
      box-shadow: $--box-shadow-base;
      border-radius: 4px;
      background-color: $--color-gray-1;

      &-item {
        color: $--color-gray-6;
        cursor: pointer;
        display: flex;
        align-items: center;
        transition: all 0.4s ease-in-out;

        &:hover {
          color: $--color-gray-7;
        }

        &:not(:first-child) {
          margin-top: 12px;
        }

        &.router-link-active {
          color: $--color-primary;

          &::before {
            background-color: $--color-primary;
          }
        }
      }
    }

    &__form {
      width: 100%;
      margin: 100px 0 0 280px;

    }
    & .is-multi {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;


      & > :nth-child(1n) {
        width: 49%;
        padding-right: 5px;
      }
    }

    .table__actions{
      display: flex;
      justify-content: center;
      & > :nth-child(n) {
        font-size: 18px;
      }

      & > :nth-child(1) {
        padding-right: 6px;
      }
    }
  }
}
.margin-bottom {
  margin-bottom: 0px;
}
</style>