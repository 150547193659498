<template>
  <div>
    <cpr-list-table
      :data="listEndossatarios"
      :colunas="colunas"
      :filtro="filtroEndossatarios"
      :acoes="['editar']"
      :editar="handleModalForm"
      :criar="handleModalForm"
      :loadData="loadData"
    />

    <modal-endossatario
      :show-modal="showModalEndossatarios"
      :handle-modal="handleModal"
      :endossatarioId="endossatarioId"
      @success="obterListEndossatarios"
    />

  </div>

</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import CprListTable from '@/layouts/CprListTable.vue';
import ModalEndossatario from './ModalEndossatario.vue';
import ServiceEndossatario from '@/services/Endossatario'
import { TipoPessoa } from '~/Enums/pessoa';
import type { IEndossatarioModel } from '~/models/endossatario';
import type { ITableColumn } from '~/types/table';

@Component({
  components: {
    CprListTable,
    ModalEndossatario
  }
})
export default class ListEndossatarios extends Vue {
  loadData = true ;
  listEndossatarios:IEndossatarioModel[] = []
  showModalEndossatarios = false;
  endossatarioId: number | null = null;

  async created (){
    await this.obterListEndossatarios();
  }

  get colunas():ITableColumn<IEndossatarioModel>[]{
    return [{
      prop: 'nomeRazao',
      sortable: true,
      label: 'Nome / Razão Social',
      align: 'center',
    },
    {
      prop: 'documento',
      sortable: true,
      label: 'CPF / CNPJ',
      align: 'center',
    },
    {
      prop: 'cidade',
      sortable: true,
      label: 'cidade',
      align: 'center',
    },
    {
      prop: 'uf',
      sortable: true,
      label: 'uf',
      align: 'center',
    },
  ]}

  async obterListEndossatarios(){
    try {
      this.listEndossatarios = (await ServiceEndossatario.getAll()).data.map( endossatario => ({
        ...endossatario,
        nomeRazao: endossatario.tipoEndossatario === TipoPessoa.PESSOA_FISICA ? endossatario.nome : endossatario.razaoSocial,
        documento: endossatario.tipoEndossatario === TipoPessoa.PESSOA_FISICA ? endossatario.cpf : endossatario.cnpj
      }))

    } catch (error) {
      this.$showMessagesErrors(error);
    } finally {
      this.loadData = false;
    }
  }

  filtroEndossatarios(filtro:string){
    return this.listEndossatarios.filter(
      ({ nome, documento }) => ( 
        nome?.toLowerCase().indexOf(filtro) !== -1 ||
        documento?.toLowerCase().indexOf(filtro) !== -1
      )
    )
  }



  handleModalForm(dado){
    this.endossatarioId = dado?.row?.endossatarioId
    this.handleModal();
  }

  handleModal(){
    this.showModalEndossatarios = !this.showModalEndossatarios;
  }

}
</script>

<style lang="scss" scoped>

</style>