import axios from 'axios';
import { logout } from '../services/auth';
import webStorage from '../common/webStorage';
import showNotify from '../utils/notificador'
//  carrega as configurações antes de montar a aplicação.
//  Na esteira de deploy foi configurado as variáveis de 
//  ambiente e marcou o JSON Transformation.

class ApiConfig {
  async set() {
    await fetch(process.env.BASE_URL + 'config/appsettings.json')
      .then((response) => response.json())
      .then((config) => {
        axios.defaults.baseURL = config.VUE_APP_API;
        axios.defaults.headers.common['Access-Control-Allow-Origin'] = config.VUE_APP_APP;
        axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,HEAD,OPTIONS,POST,PUT';

        axios.interceptors.request.use(confg => {
          if (confg?.headers && webStorage.getItem('token')) {
            confg.headers.common['Authorization'] = `Bearer ${webStorage.getItem('token')}`
          }
          return confg
        }, error => {
          return Promise.reject(error)
        })

        axios.interceptors.response.use(response => {
          return response
        }, error => {      
          if (axios.isCancel(error)) return Promise.reject({...error});
          const status = error.response.status;

          switch (status) {
            case 400:
              return Promise.reject(error.response.data);
            case 401:
              logout();
              break;
            case 403:
              showNotify('error', 'Seu usuário não tem permissão para essa ação, tente realizar o login em outra conta!', 'Acesso Negado');
              return Promise.reject(error.response);
            default:
              return Promise.reject(error.response);
          }
        })
      }
    );
  }
}

export default new ApiConfig();