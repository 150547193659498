export default {
  path: 'modelo-minuta/:modeloId?',
  component: () => import('~/views/management/modelo-minuta/index.vue'),
  name: 'ModeloMinuta',
  meta: {
    requiresAuth: true,
    routeAdmin: true,
    breadcrumb: 'Modelo de Minuta'
  },
  props: true
};
