/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import extenso from 'extenso';
import showNotify from './notificador';
import { ItemLista, MessageErrorType } from '~/types/types';
import { isDate } from 'lodash';
import { AxiosError } from 'axios';
import { ILabel } from '~/types/label';
import { IToken } from '~/types/token';

/**
 * 
 * @param value Aceita o formato (YYYY/MM/DD)
 * @returns ex.: treze de janeiro de dois mil e vinte e dois
 */
export const dataPorExtenso = (value?: string | Date) => {
  if(!value) return ''
  const data = moment(value).format('LL');  //13 de janeiro de 2022
  const dataSplit = data.split(' ');
  let dataExtenso = '';  
 
  dataSplit.forEach((value, index) => {
    if(index === 0 || dataSplit.length === (index + 1) ){
      dataExtenso += `${extenso(value)} ` 
    }
    else {
      dataExtenso += `${value} `
    }
  })
  
  return dataExtenso;
}

export const dataFormatada = (value?: string | Date) => {
  return !value ?  '' : moment(value).format('L');
}


export const removeEmptyProperties = (obj: Record<string, any>) => {
  const copy = {...obj };

  for (const [key, value] of Object.entries(copy)) {
    if (value === null || value === undefined 
      || (typeof value === 'string' && value.trim() === '') 
      || (typeof value === 'object' && Object.keys(value).length === 0 && !isDate(value))
    ) {
      delete copy[key];
    } else if (typeof value === 'object') {
      removeEmptyProperties(value);
    }
  }
  // Retorna a cópia atualizada
  return copy;
}

export const getAllNestedProperties = (obj: Record<string, any>, parentKey = ''): string[] => {
  const properties: string[] = [];

  for (const [key, value] of Object.entries(obj)) {
    const nestedKey = parentKey ? `${parentKey}.${key}` : key;
    properties.push(nestedKey);

    if ( typeof value === 'object' 
      && value !== null 
      && !(value instanceof Date) 
      && Object.keys(value).length > 0
    ) {
      properties.push(...getAllNestedProperties(value, nestedKey));
    }
  }

  return properties;
};

export const getNestedPropertyValue = <T>(obj: T, key: string | number) => {
  if(typeof key === 'number') return obj[key]
  const properties = key.split('.');
  let value = obj;

  for (const property of properties) {
    if (value && typeof value === 'object' && property in value) {
      value = value[property];
    } else {
      return undefined;
    }
  }

  return value;
}

export const dictionaryConvertion = <T>(obj: T):ILabel<T>[] => {
  const tipo:ILabel[] = []
  for (const key in obj) {
    const convert = Number(key)
    const value = isNaN(convert) ? key : convert
    tipo.push({
      value,
      label: obj[key] as string
    })
  }
  return tipo;
}

export const decodedToken = <T = IToken>(token:string):T => {
  return JSON.parse(atob(token.split('.')[1]))
}

export const isTokenExpired = (token: string) => {
  if (!token) return false;
  const decoded = decodedToken(token);
  const expirationTimestamp = decoded.exp * 1000;
  const currentTimestamp = Date.now();

  return currentTimestamp < expirationTimestamp;
};

export const separarCamelCase = (str = '') => {
  const regex = /([a-z])([A-Z])|([A-Z])([A-Z][a-z])/g
  if (!regex.test(str)) {
    return str; // retorna a string original se não houver palavras CamelCase
  }
  return str.replace(regex, '$1 $2');
}


export function findModifiedObjects<T>(
  oldArray: T[],
  newArray: T[]
): Array<T> {
  const modifiedObjects: Array<T> = [];

  const compareObjects = (obj1: T, obj2: T) =>
    Object.keys(obj1 as object).every((prop) => obj1[prop] === obj2[prop]);

  for (const newObj of newArray) {
    const keyProp = Object.keys(newObj as object).find(
      (prop) =>
        oldArray.find((oldObj) => compareObjects(oldObj, newObj))?.[prop] !==
        newObj[prop]
    );
    if (keyProp) {
      modifiedObjects.push({ ...newObj, [keyProp]: newObj[keyProp] });
    }
  }

  return modifiedObjects;
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const clearArray = function (val: Array<any>) {
  val.splice(0, val.length)
}


export function removerItemLista<T extends ItemLista<T>>(params: { id: number | string, lista: T[] }): void {
  const index = params.lista.findIndex((item) => (item.id === params.id || item.value === params.id));
  if (index !== -1) params.lista.splice(index, 1);
}

const focusElement = (element: HTMLElement | null) => {
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  }
}

/**
* Retorna um array dos erros que a API lançou como Notification.
* @param error Erro capturado no try catch, podendo ser uma promise ou objeto
* @return Array de strings
*/
const getRequisitionNotifications = async (error: any) => {
  return await Promise.resolve(error)
    .then((result) => {
      return result?.errors ?? result?.data?.errors ?? null
    })
}

/**
* Retorna um array dos erros que a API lançou como .
* @param er Erro capturado no try catch
* @return Array de strings
*/
const getAxiosErrors = (er: any) => { // Para requisições AXIOS que retorna Erro em vez de Notification
  const errors:any = []
  if (!er?.data?.errors) {
    return null
  }
  er.data.errors.map((res) => {
    res.map((object) => {
      errors.push(object.error)
    })
  })
  return errors
}

/**
* Exibe ao usuário a Notification(erro) que o back retorna. Caso, o back não trate o erro ou aconteça algum problema
* na requsição uma mensagem padrão será disparada!
* OBS.: Trata exceções AXIOS e FETCH
* @param error Erro capturado no try catch
*/
export const showCapturedNotification = async (error: AxiosError, type: MessageErrorType = 'error', title = '') => {
  if(error?.code === 'ERR_CANCELED') return false;
  const notifications = await getRequisitionNotifications(error) ?? getAxiosErrors(error)
  const defaultTitle = 'Não foi possível executar a ação!';
  const defaultMessage = '<li>Atualize a página e tente novamente! Caso o erro persista, contate o suporte.</li>'
  try {
    let lines = ''
    notifications.forEach((message) => {
      lines = lines + `<li>${message} </li>`  
    })
    showNotify(type, lines, title)
  } catch {
    showNotify('error', defaultMessage, title ?? defaultTitle);
  }
}

export const functions = {
  clearArray,
  dataFormatada,
  dataPorExtenso,
  showCapturedNotification,
  removeEmptyProperties,
  removerItemLista,
  separarCamelCase,
  dictionaryConvertion,
  focusElement
}

export default functions