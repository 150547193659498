<template>
  <cpr-modal-form
    :show-modal="showModal"
    :outsideClick="false"
    :title="(endossatarioId ? 'Editar' : 'Novo') + ' endossatário'"
    :formModel="formEndossatario"
    :cancel="cancelar"
    :submit="enviar"
    :nameSubmit="endossatarioId ? 'Editar' : 'Adicionar'"
    :loading="loadModal"
  >
    <cpr-radio-button
      v-model="formEndossatario.tipoEndossatario"
      :disabled="endossatarioId !== undefined || null"
      :data="tipoPessoa"
      label="Tipo Pessoa"
    />

    <cpr-form-section label="Inscrições Estaduais" prop="inscricoesEstaduais">
      <el-form-item
        v-for="(item, index) in formEndossatario.inscricoesEstaduais"
        :key="`insc${index}`"
        :prop="'inscricoesEstaduais.' + index + '.numInscricaoEstadual'"
        :label="`Inscrição Estadual ${index + 1}`"
      >
        <cpr-input
          v-model="item.numInscricaoEstadual"
          v-if="formEndossatario.inscricoesEstaduais"
        >
          <template #action>
            <el-button
              class="input__icon icon-button"
              icon="el-icon-delete"
              v-if="
                !item.inscricaoEstadualId &&
                (index !== 0 || formEndossatario.inscricoesEstaduais.length > 1)
              "
              @click="deleteValue('inscricaoEstadual', index)"
            />
            <el-button
              class="input__icon icon-button"
              icon="el-icon-plus"
              v-if="index === formEndossatario.inscricoesEstaduais.length - 1"
              @click="addValue('inscricaoEstadual')"
            />
          </template>
        </cpr-input>
      </el-form-item>
    </cpr-form-section>

    <cpr-form-section
      v-show="formEndossatario.tipoEndossatario === 'PF'"
      label="Dados gerais"
      prop="tipoEndossatarioPF"
    >
      <el-form-item
        prop="nome"
        id="nome"
        label="Nome"
        :rules="validationPessoaFisica(['required'])"
      >
        <cpr-input
          v-model="formEndossatario.nome"
          placeholder="Nome completo"
        />
      </el-form-item>

      <el-form-item
        prop="cpf"
        label="CPF"
        id="cpf"
        v-mask="'###.###.###-##'"
        :rules="validationPessoaFisica(['required', 'cpf'])"
      >
        <cpr-input
          v-model.lazy="formEndossatario.cpf"
          placeholder="000.000.000-00"
        />
      </el-form-item>

      <el-form-item prop="rg" label="RG">
        <cpr-input v-model="formEndossatario.rg" placeholder="00000000" />
      </el-form-item>
      <el-form-item prop="orgaoExpedidorRg" label="Orgão Expedidor">
        <cpr-input
          v-model="formEndossatario.orgaoExpedidorRg"
          placeholder="SSP"
        />
      </el-form-item>

      <el-form-item prop="emissaoRg" label="Emissão">
        <cpr-input
          v-model="formEndossatario.emissaoRg"
          isDate
          placeholder="DD/MM/AAAA"
        />
      </el-form-item>
      <el-form-item prop="nacionalidade" label="Nacionalidade">
        <cpr-input
          v-model="formEndossatario.nacionalidade"
          placeholder="Brasileira"
        />
      </el-form-item>

      <el-form-item prop="naturalidade" label="Naturalidade">
        <cpr-input v-model="formEndossatario.naturalidade" />
      </el-form-item>
      <el-form-item prop="profissao" label="Profissão">
        <cpr-input v-model="formEndossatario.profissao" placeholder="Admin" />
      </el-form-item>

      <el-form-item
        prop="estadoCivil"
        label="Estado Civil"
        id="estadoCivil"
        :rules="validationPessoaFisica(['required'])"
      >
        <cpr-input
          v-model="formEndossatario.estadoCivil"
          placeholder="Selecione o estado civil"
          isSelect
          @change="(ec) => changeEstadoCivil(ec)"
        >
          <el-option
            v-for="item in estadocivil"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </cpr-input>
      </el-form-item>
    </cpr-form-section>

    <cpr-form-section
      v-if="ehCasado && formEndossatario.conjuge"
      label="Conjuge"
      prop="conjuge"
    >
      <el-form-item prop="conjuge.nome" label="Nome">
        <cpr-input v-model="formEndossatario.conjuge.nome" />
      </el-form-item>

      <el-form-item
        prop="conjuge.cpf"
        id="conjuge.cpf"
        label="CPF"
        :rules="validationPessoaFisica(['required'])"
      >
        <cpr-input
          v-model="formEndossatario.conjuge.cpf"
          placeholder="000.000.000-00"
          v-mask="'###.###.###-##'"
        />
      </el-form-item>
      <el-form-item prop="conjuge.rg" label="RG">
        <cpr-input
          v-model="formEndossatario.conjuge.rg"
          placeholder="00000000"
        />
      </el-form-item>

      <el-form-item prop="conjuge.orgaoExpedidorRg" label="Orgão Expedidor">
        <cpr-input
          v-model="formEndossatario.conjuge.orgaoExpedidorRg"
          placeholder="SSP"
        />
      </el-form-item>

      <el-form-item prop="conjuge.emissaoRg" label="Emissão">
        <cpr-input
          v-model="formEndossatario.conjuge.emissaoRg"
          isDate
          placeholder="DD/MM/AAAA"
        />
      </el-form-item>

      <el-form-item prop="conjuge.nacionalidade" label="Nacionalidade">
        <cpr-input
          v-model="formEndossatario.conjuge.nacionalidade"
          placeholder="Brasileira"
        />
      </el-form-item>

      <el-form-item prop="conjuge.profissao" label="Profissão">
        <cpr-input
          v-model="formEndossatario.conjuge.profissao"
          placeholder="Engenheiro"
        />
      </el-form-item>

      <el-form-item prop="conjuge.email" label="Email">
        <cpr-input
          v-model="formEndossatario.conjuge.email"
          placeholder="email@email.com"
        />
      </el-form-item>
      <cpr-form-input
        v-model="formEndossatario.conjuge.telefone"
        prop="conjuge.telefone"
        placeholder="(DD) XXXXX-XXXX"
        label="Telefone"
        mask="telefone"
      />
    </cpr-form-section>

    <cpr-form-section
      v-show="formEndossatario.tipoEndossatario === 'PJ'"
      label="Dados gerais"
      prop="tipoEndossatarioPJ"
    >
      <el-form-item
        prop="razaoSocial"
        id="razaoSocial"
        label="Razão Social"
        :rules="validationPessoaJuridica(['required'])"
      >
        <cpr-input
          v-model="formEndossatario.razaoSocial"
          placeholder="Razão Social"
        />
      </el-form-item>

      <el-form-item
        prop="nomeFantasia"
        id="nomeFantasia"
        label="Nome Fantasia"
        :rules="validationPessoaJuridica(['required'])"
      >
        <cpr-input
          v-model="formEndossatario.nomeFantasia"
          placeholder="Nome fantasia"
        />
      </el-form-item>
      <el-form-item
        prop="cnpj"
        id="cnpj"
        label="CNPJ"
        :rules="validationPessoaJuridica(['required', 'cnpj'])"
      >
        <cpr-input
          v-model="formEndossatario.cnpj"
          v-mask="'##.###.###/####-##'"
          placeholder="00.000.000/0000-00"
        />
      </el-form-item>
    </cpr-form-section>
    <cpr-form-section
      label="Representante"
      prop="representante"
      v-if="
        formEndossatario.representante &&
        formEndossatario.tipoEndossatario === 'PJ'
      "
    >
      <el-form-item
        prop="representante.nome"
        id="representante.nome"
        label="Nome"
        :rules="validationPessoaJuridica(['required'])"
      >
        <cpr-input v-model="formEndossatario.representante.nome" />
      </el-form-item>

      <el-form-item
        prop="representante.cpf"
        id="representante.cpf"
        label="CPF"
        :rules="validationPessoaJuridica(['required', 'cpf'])"
        v-mask="'###.###.###.###-##'"
      >
        <cpr-input
          v-model="formEndossatario.representante.cpf"
          placeholder="000.000.000-00"
        />
      </el-form-item>

      <el-form-item prop="representante.rg" label="RG">
        <cpr-input v-model="formEndossatario.representante.rg" />
      </el-form-item>

      <el-form-item
        prop="representante.email"
        id="representante.email"
        label="E-mail"
        :rules="validationPessoaJuridica(['required', 'email'])"
      >
        <cpr-input v-model="formEndossatario.representante.email" />
      </el-form-item>

      <cpr-form-input
        v-model="formEndossatario.representante.telefone"
        prop="representante.telefone"
        placeholder="(DD) XXXXX-XXXX"
        label="Telefone"
        mask="telefone"
      />

      <el-form-item prop="representante.cargo" label="Cargo">
        <cpr-input v-model="formEndossatario.representante.cargo" />
      </el-form-item>
    </cpr-form-section>

    <cpr-form-section
      label="Contato"
      prop="contato"
      v-if="formEndossatario.contato"
    >
      <el-form-item
        v-for="(item, index) in formEndossatario.contato.emailEndereco"
        :key="`email${index}`"
        :prop="`contato.emailEndereco.${index}.emailEndereco`"
        :id="`contato.emailEndereco.${index}.emailEndereco`"
        :label="`Email ${index + 1}`"
        :rules="$validationForm(['required', 'email'])"
      >
        <cpr-input v-model="item.emailEndereco" placeholder="email@email.com">
          <template #action>
            <el-button
              class="input__icon icon-button"
              icon="el-icon-delete"
              v-if="
                !item.emailId &&
                (index !== 0 ||
                  formEndossatario.contato.emailEndereco.length > 1)
              "
              @click="deleteValue('emailEndereco', index)"
            />
            <el-button
              class="input__icon icon-button"
              icon="el-icon-plus"
              v-if="index === formEndossatario.contato.emailEndereco.length - 1"
              @click="addValue('emailEndereco')"
            />
          </template>
        </cpr-input>
      </el-form-item>

      <el-form-item
        v-for="(item, index) in formEndossatario.contato.numero"
        :key="`cell${index}`"
        :prop="`contato.numero.${index}.numero`"
        :id="`contato.numero.${index}.numero`"
        :label="`Celular ${index + 1}`"
        :rules="$validationForm(['required'])"
      >
        <cpr-input
          v-model="item.numero"
          placeholder="(xx) xxxxx-xxxx"
          v-mask="'(##) #####-####'"
        >
          <template #action>
            <el-button
              class="input__icon icon-button"
              icon="el-icon-delete"
              v-if="
                !item.telefoneId &&
                (index !== 0 || formEndossatario.contato.numero.length > 1)
              "
              @click="deleteValue('celular', index)"
            />
            <el-button
              class="input__icon icon-button"
              icon="el-icon-plus"
              v-if="index === formEndossatario.contato.numero.length - 1"
              @click="addValue('celular')"
            />
          </template>
        </cpr-input>
      </el-form-item>
    </cpr-form-section>

    <cpr-form-section label="Endereço" prop="endereco">
      <el-form-item prop="cep" label="CEP">
        <cpr-input
          v-model.lazy="formEndossatario.cep"
          placeholder="00000-000"
          v-mask="'#####-###'"
          @change="(value) => searchEndereco(value)"
          :suffix-icon="`${loadCep ? 'el-icon-loading' : ''}`"
        />
      </el-form-item>

      <el-form-item prop="rua" label="Rua">
        <cpr-input v-model="formEndossatario.rua" placeholder="Rua X" />
      </el-form-item>

      <el-form-item prop="complemento" label="Complemento">
        <cpr-input
          v-model="formEndossatario.complemento"
          placeholder="Endereço completo do endossatario"
        />
      </el-form-item>

      <el-form-item prop="bairro" label="Bairro">
        <cpr-input v-model="formEndossatario.bairro" placeholder="Bairro" />
      </el-form-item>

      <el-form-item prop="cidade" label="Cidade">
        <cpr-input v-model="formEndossatario.cidade" placeholder="Cidade" />
      </el-form-item>
      <el-form-item prop="uf" label="UF">
        <cpr-input
          v-model="formEndossatario.uf"
          placeholder="UF"
          maxlength="2"
          v-uppercase 
        />
      </el-form-item>

      <el-form-item prop="numero" label="Numero">
        <cpr-input v-model="formEndossatario.numero" placeholder="S/N" />
      </el-form-item>
    </cpr-form-section>
  </cpr-modal-form>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue, Watch } from 'vue-property-decorator';
import CprModalForm from '@/components/CprModalForm.vue';
import CprForm from '~/components/cpr-form/cpr-form.vue';
import CprFormSection from '@/components/cpr-form/cpr-form-section.vue';
import CprInput from '@/components/Input.vue';
import { TipoPessoa } from '~/Enums/pessoa';
import type { IEndossatarioModel } from '@/models/endossatario';
import type { IConjugeModel } from '~/models/conjuge';
import ServiceCliente from '@/services/Cliente';
import ServiceEndossatario from '@/services/Endossatario';
import { IUsuarioModel } from '~/types/usuario';
import webStorage from '~/common/webStorage';
import { IRepresentanteModel } from '~/models/representante';
import { formatarCEP } from '~/utils/formats';
import { ValidationRule } from '~/utils/validationForm';

@Component({
  components: {
    'cpr-modal-form': CprModalForm,
    CprFormSection,
    'cpr-form': CprForm,
    'cpr-input': CprInput
  }
})
export default class ModalEndossatario extends Vue {
  @Prop() handleModal!: () => void;
  @Prop({ default: false }) showModal!: boolean;
  @Prop() endossatarioId!: number;
  @VModel({ default: () => [] }) endossatarioList!: IEndossatarioModel[];

  usuario = {} as IUsuarioModel;
  formEndossatario = {
    tipoEndossatario: TipoPessoa.PESSOA_FISICA,
    inscricoesEstaduais: [
      {
        numInscricaoEstadual: ''
      }
    ],
    representante: {} as IRepresentanteModel,
    estadoCivil: '',
    conjuge: {} as IConjugeModel,
    contato: {
      emailEndereco: [
        {
          emailEndereco: ''
        }
      ],
      numero: [
        {
          numero: ''
        }
      ]
    }
  } as IEndossatarioModel;

  loadModal = false;
  loadCep = false;

  get tipoPessoa() {
    return [
      {
        prop: TipoPessoa.PESSOA_FISICA,
        label: 'Pessoa Física'
      },
      {
        prop: TipoPessoa.PESSOA_JURIDICA,
        label: 'Pessoa Jurídica'
      }
    ];
  }

  get ehCasado() {
    return this.formEndossatario.estadoCivil?.toLocaleLowerCase() === 'casado';
  }

  get estadocivil() {
    return [
      {
        value: 'Solteiro',
        label: 'Solteiro'
      },
      {
        value: 'Casado',
        label: 'Casado'
      },
      {
        value: 'Divorciado',
        label: 'Divorciado'
      },
      {
        value: 'Convivente',
        label: 'Convivente'
      },
      {
        value: 'Viuvo',
        label: 'Viúvo'
      },
      {
        value: 'Outro',
        label: 'Outro'
      }
    ];
  }

  mounted() {
    this.usuario = webStorage.getItem('usuario');
  }

  validationPessoaFisica(validations: ValidationRule[]) {
    return this.formEndossatario.tipoEndossatario === TipoPessoa.PESSOA_FISICA
      ? this.$validationForm(validations)
      : [''];
  }

  validationPessoaJuridica(validations: ValidationRule[]) {
    return this.formEndossatario.tipoEndossatario === TipoPessoa.PESSOA_JURIDICA
      ? this.$validationForm(validations)
      : [''];
  }

  async enviar() {
    this.formEndossatario = {
      ...this.formEndossatario,
      cep: formatarCEP(this.formEndossatario.cep),
      empresaId: this.usuario.empresaId
    };

    this.loadModal = !this.loadModal;

    const payload = this.$removeEmptyProperties({
      ...this.formEndossatario
    }) as IEndossatarioModel;

    try {
      const res = (await ServiceEndossatario.save(payload, this.endossatarioId))
        .data;

      this.endossatarioList.push(res);
      this.$emit('success', res);

      this.handleModal();
      this.$showNotify(
        'success',
        `Endossatário ${
          this.endossatarioId ? 'editado' : 'cadastrado'
        } com sucesso!`
      );
    } catch (error) {
      this.$showMessagesErrors(error);
      throw Error;
    } finally {
      this.loadModal = !this.loadModal;
    }
  }

  formPadrao() {
    this.formEndossatario = {
      tipoEndossatario: TipoPessoa.PESSOA_FISICA,
      inscricoesEstaduais: [
        {
          numInscricaoEstadual: ''
        }
      ],
      representante: {} as IRepresentanteModel,
      estadoCivil: '',
      conjuge: {} as IConjugeModel,
      contato: {
        emailEndereco: [
          {
            emailEndereco: ''
          }
        ],
        numero: [
          {
            numero: ''
          }
        ]
      }
    } as IEndossatarioModel;
  }

  cancelar() {
    this.handleModal();
  }

  addValue(type: string) {
    switch (type) {
      case 'celular':
        this.formEndossatario.contato?.numero.push({ numero: '' });
        break;

      case 'emailEndereco':
        this.formEndossatario.contato?.emailEndereco.push({
          emailEndereco: ''
        });
        break;

      case 'inscricaoEstadual':
        this.formEndossatario.inscricoesEstaduais?.push({
          numInscricaoEstadual: ''
        });
        break;

      default:
        break;
    }
  }

  deleteValue(type: string, index: number) {
    switch (type) {
      case 'celular':
        this.formEndossatario.contato?.numero.splice(index, 1);
        break;

      case 'emailEndereco':
        this.formEndossatario.contato?.emailEndereco.splice(index, 1);
        break;

      case 'inscricaoEstadual':
        this.formEndossatario.inscricoesEstaduais?.splice(index, 1);
        break;

      default:
        break;
    }
  }

  changeEstadoCivil(estadoCivil: string) {
    estadoCivil === 'Casado' &&
    (this.formEndossatario.conjuge === null || undefined)
      ? (this.formEndossatario.conjuge = {} as IConjugeModel)
      : '';
  }

  changeTipoPessoa(tipoPessoa: TipoPessoa) {
    this.formEndossatario.tipoEndossatario = tipoPessoa;
  }

  searchEndereco(search: string) {
    this.loadCep = !this.loadCep;
    const cep = this.$formatarCEP(search);
    ServiceCliente.buscaCep(cep)
      .then((data) => {
        this.formEndossatario.rua = data.logradouro;
        this.formEndossatario.bairro = data.bairro;
        this.formEndossatario.uf = data.uf;
        this.formEndossatario.cidade = data.localidade;
      })
      .catch((error) => {
        this.$showMessagesErrors(error);
      })
      .finally(() => {
        this.loadCep = !this.loadCep;
      });
  }

  get isInscricoesEstaduais() {
    return (this.formEndossatario.inscricoesEstaduais?.length ?? 0) > 0;
  }

  @Watch('showModal')
  async onShowModal(open) {
    this.formPadrao();
    if (this.endossatarioId && open) {
      try {
        this.formEndossatario = (
          await ServiceEndossatario.getById(this.endossatarioId)
        ).data;
        const representante =
          this.formEndossatario.tipoEndossatario === 'PJ' &&
          !this.formEndossatario.representante
            ? ({} as IRepresentanteModel)
            : this.formEndossatario.representante;

        const inscricoesEstaduais = this.isInscricoesEstaduais
          ? this.formEndossatario?.inscricoesEstaduais
          : [{ numInscricaoEstadual: '' }];

        this.formEndossatario = {
          ...this.formEndossatario,
          cep: this.$formatarCEP(this.formEndossatario.cep, true),
          representante: representante,
          inscricoesEstaduais
        };
      } catch (error) {
        this.$showMessagesErrors(error);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input__icon {
  background-color: get-color(neutral, ultra-light);
  border-color: get-color(neutral, pure);

  &:hover {
    background: rgba(get-color(primary, high-light), 0.1);
    border-color: get-color(primary, pure);
  }
}
</style>
