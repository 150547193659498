import Vue from 'vue';
import Vuex from 'vuex';
import auth, { type AuthState } from './modules/auth';
import parametrizacoes, { type ParametrizacaoState } from './modules/parametrizacao';
import conta, { type ContaState } from './modules/conta';

export interface RootState {
  auth: AuthState;
  parametrizacoes: ParametrizacaoState
  conta: ContaState
}

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  modules: {
    auth,
    parametrizacoes,
    conta
  },
  strict: process.env.NODE_ENV !== 'production',
});
