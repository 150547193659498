export enum FileStreamType {
  PDF = 'application/pdf',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  GIF = 'image/gif'
}

export const FileStreamTypeDescription: Record<FileStreamType, string> = {
  [FileStreamType.PDF]: 'Arquivo PDF',
  [FileStreamType.JPEG]: 'Imagem JPEG',
  [FileStreamType.PNG]: 'Imagem PNG',
  [FileStreamType.GIF]: 'Imagem GIF'
};

export const FileTypeDescriptionResume: Record<FileStreamType, string> = {
  [FileStreamType.PDF]: 'PDF',
  [FileStreamType.JPEG]: 'JPEG',
  [FileStreamType.PNG]: 'PNG',
  [FileStreamType.GIF]: 'GIF'
};

export const FileTypeMIME: Record<string, FileStreamType> = {
  PDF: FileStreamType.PDF,
  JPEG: FileStreamType.JPEG,
  JPG: FileStreamType.JPEG, 
  PNG: FileStreamType.PNG,
  GIF: FileStreamType.GIF,
};
