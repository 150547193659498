import { IFazendaModel } from '~/models/fazenda';
import baseService from '../baseServise';
import axios from 'axios';

class ServiceFazenda extends baseService<IFazendaModel> {
  constructor() {
    super('Fazenda')
  }

  async deleteProprietario(fazendaId:number | string, proprietarioId:number):Promise<void>{
    axios.delete(`${this.baseURL}/${fazendaId}/proprietario/${proprietarioId}`)
  }

}

export default new ServiceFazenda();
