<template>
  <el-upload
    v-bind="$attrs"
    action="#"
    :http-request="() => {}"
    list-type="picture"
    :on-remove="() => {}"
    :before-upload="handleFileUpload"
    :multiple="true"
    :limit="limit"
    :accept="accept"
    :file-list="fileList"
    drag
    class="cpr-upload"
  >
    <template v-slot:default v-if="!disabled">
      <span class="cpr-upload__icon">
        <cpr-icon iconName="icon-document-upload" :size="95" />
      </span>
      <div class="cpr-upload__tip">
        <p><b>Envie seu documento</b></p>
        <p>
          <b>Arraste</b> e <b>solta aqui</b>, ou <b>localize</b> o seu arquivo.
          Ele deve ser no formato <b>PNG, JPEG, JPG</b> ou <b>PDF</b> e ter no
          <b>máximo 2mb</b>.
        </p>
      </div>
    </template>

    <template v-slot:file="{ file }">
      <div class="cpr-upload__file">
        <span>
          <img
            class="el-upload-list__item-thumbnail"
            id="content-upload"
            v-bind:src="file.url || file.base64String"
            alt=""
            v-if="file.type !== fileStreamType.PDF"
          />
          <span v-else class="file-rendering">
            <cpr-icon  iconName="icon-file-pdf" :size="100"/>
          </span>
        </span>
        <div class="cpr-upload__file-info">
          <div class="cpr-upload__file-info__content">
            <p>{{ file.name }}</p>
            <p>{{ fileStreamSize[file.type] }} - {{ getFileSize(file) }}</p>
          </div>
          <el-dropdown trigger="click">
            <el-button size="mini" class="btn btn-tertiary" style="width: 50px">
              <i style="transform: rotate(-90deg)" class="el-icon-more"></i>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item v-if="false">
                  <el-button
                    type="text"
                    size="mini"
                    class="btn btn-tertiary"
                    @click="handleEditeFile(file)"
                  >
                    <i class="el-icon-edit-outline"></i>
                    Editar
                  </el-button>
                </el-dropdown-item>

                <el-dropdown-item>
                  <el-button
                    type="text"
                    size="mini"
                    class="btn btn-tertiary"
                    @click="handleDownload(file)"
                  >
                    <i class="el-icon-download"></i>
                    Baixar
                  </el-button>
                </el-dropdown-item>
                <el-dropdown-item divided>
                  <el-button
                    type="text"
                    size="mini"
                    class="btn btn-tertiary is-danger"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                    Excluir
                  </el-button>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </template>
  </el-upload>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CprIcon from '@/components/CprIcon.vue';
import { FileStreamType, FileStreamTypeDescription } from '~/Enums/fileStream';
import { IFile } from '@/types/file';


@Component({
  name: 'cpr-upload-preview',
  components: {
    CprIcon,
  },
})
export default class CprUploadPreview extends Vue {
  @Prop({ default: `${ FileStreamType.JPEG }, ${ FileStreamType.PNG }, ${ FileStreamType.PDF }`}) accept!: string;
  @Prop({ default: false }) isMultiple!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: 10 }) limit!: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop() value!: any[];
  @Prop() deleteAsync!:(file:IFile)=> Promise<void>;

  /** @exemplo
   *{
      name: 'food2.jpeg', 
      url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
      base64String: 'aaaa',
      size: 1500,
      type: FileStreamType.JPEG
    }
   */
  fileList: IFile[] = [];

  get fileStreamSize() {
    return FileStreamTypeDescription;
  }

  get fileStreamType() {
    return FileStreamType;
  }


  created() {
    if (this.value) {
      this.fileList = this.value;
    }
  }

  disabledUpload(disabled:boolean){
    const upload = document.querySelectorAll('.el-upload');
    for (const iterator of upload) {
      iterator?.setAttribute('style',  `display: ${ disabled ? 'none' : 'inline-block' }`);
      iterator.querySelector('.el-upload-dragger')?.setAttribute('style',  `border: ${ disabled ? 'none' : '1px dashed #59D64C' }`);
    }
  }

  mounted() {
    this.disabledUpload(this.disabled);
  }

  destroyed(){
    this.disabledUpload(false);
  }

  validarFileUpload(file:IFile) {
    const isLt2M = file.size / 1024 / 1024 < 2;
    const tiposAceitos = this.accept.match(/[^,\s]+/g) as RegExpMatchArray ;

    if (!tiposAceitos?.some( a => file.type.includes(a))) {
      const formatosAceitos = tiposAceitos.map((tipo) => FileStreamTypeDescription[tipo].split(' ')[1]).join(', '); 
      this.$showNotify(
        'info',
        `Apenas arquivos nos formatos: <b>${formatosAceitos}</b> são aceitos!`,
        'Formato inválido!'
      );
      return false;
    }
    if (!isLt2M) {
      this.$showNotify(
        'info',
        'Seu arquivo não pode exceder o tamanho máximo de 2MB!',
        'Tamanho excedido!'
      );
      return false;
    }

    return true;
  }

  getFileSize(file:IFile) {
    const fileSizeInBytes = file.size;
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024); // Tamanho em MB

    if (fileSizeInMB >= 1) {
      return fileSizeInMB.toFixed(2) + ' MB';
    } else {
      const fileSizeInKB = fileSizeInBytes / 1024; // Tamanho em KB
      return fileSizeInKB.toFixed(2) + ' KB';
    }
  }

  handleRemove(file: IFile) {
    const index = this.fileList.indexOf(file);
    if (index === -1) return false;

    if(file?.id)
      this.$cprAlert({
        type: 'delete',
        title: 'Excluindo Arquivo!',
        textInfo: `Você está excluindo o arquivo <b>${ file.name }</b>. Caso confirme, <b>os dados serão ` + 
          'excluídos</b> e <b>não poderão ser recuperados</b>. O que você deseja fazer?',
        namePositiveAction: 'Manter Arquivo',
        nameNegativeAction: 'Excluir Arquivo',
        negativeAction: async ()=> {
          try {
            await this.deleteAsync(file);
            this.fileList.splice(index, 1);
            this.$emit('delete', index);
            
          } catch (error) {
            this.$showMessagesErrors(error);
          }
        }
      })
    else {
      this.fileList.splice(index, 1);
      this.$emit('delete', index);
    }
  }

  base64ToFile(base64String, filename, mimeType) {
    const arr = base64String.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mimeType || mime });
  }

  handleDownload(file: IFile) {
    const { url, base64String, name, type } = file;
    const anchor = document.createElement('a');
    anchor.href = url || base64String || '';
    anchor.download = name;
    anchor.target = '_blank';

    if (type === FileStreamType.PDF && base64String) {
      const mimeType = 'application/pdf';
      const fileObj = this.base64ToFile(base64String, name, mimeType);
      anchor.href = URL.createObjectURL(fileObj);
    }

    anchor.click();
  }

  handleEditeFile(file: IFile) {
    console.log(file);
  }

  handleFileUpload(file) {
    if (!this.validarFileUpload(file)) return false;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader?.result as string;
      this.fileList.push({
        base64String: base64String,
        name: file.name,
        size: file.size,
        type: file.type,
      });
    };
  }

  @Watch('value')
  onValue(value) {
    this.fileList = value;
  }

  @Watch('fileList')
  onBase64String(value) {
    this.$emit('input', value);
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/mixings/_responsive-grid';
@import '../../styles/mixings/_screen';
.cpr-upload {
  grid-column: 1 /-1 !important;
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
  margin-bottom: 20px;

  &__icon {
    height: 175px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: get-color(primary, low-medium);
  }

  &__tip {
    color: get-color(primary, low-medium);
    font-size: 13px;
    background-color: get-color(primary, ultra-light);
    border-radius: 0px 0px 7px 7px;
    border-top: 1px dashed get-color(primary, pure);
    display: flex;
    flex-direction: column;
    padding: 24px;

    > :first-child {
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 600;
    }
  }

  &__file {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    & > span {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      background: #FFF;
      
      .file-rendering {
        border-radius: 100%;
        background-color: get-color(primary, low-medium);
        height: 150px;
        width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        
       & > svg > :nth-child(n) {
         fill: #fff;
       }
        
      }
    }

    &-info {
      color: get-color(neutral, low-medium);
      font-size: 13px;
      background-color: get-color(neutral, ultra-light);
      border-top: 1px solid get-color(neutral, pure);
      display: flex;
      justify-content: space-between;
      padding: 24px;
      height: 90px;
      
      &__content {
        width: 85%;
        > :first-child {
          margin-bottom: 8px;
          font-size: 14px;
          font-weight: 600;
        }

        p{
          white-space: nowrap;
          overflow: hidden; 
          text-overflow: ellipsis;
        }
        
      }
    }
  }
}


/** dropdown-menu */
  .el-dropdown {
    &:hover {
      border-left-color: #cdf3c9;
    }

    &-menu {
      padding: 7px 0px;

      &__item {
        padding: 0;
        min-width: 100px;

        & > :nth-child(n) {
          width: 100%;
          text-align: left;
          padding: 0 20px;
        }
        
        &--divided:before {
          margin: 0;
        }
      }
    }
  }
  .el-dropdown-menu__item:focus,
  .el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: transparent;
  }
  .el-dropdown-menu__item {
    & > :nth-child(n) {
      height: 32px;
    }
  }
//

//.el-upload {
//  .el-upload-dragger:hover + .upload__tip,
//  .el-upload-dragger:focus + .upload__tip {
//    background-color: get-color(primary, hover-color);
//  }

//  .el-upload-dragger.is-hover + .upload__tip {
//    background-color: get-color(primary, hover-color);
//  }

//  .el-upload-dragger.is-dragover + .upload__tip {
//    background-color: get-color(primary, dragover-color);
//  }
//}

::v-deep .el-upload-list--picture {
  row-gap: 16px;
  @include responsive-grid;


  .el-upload-list__item {
    background-color: #fcfdf6;
    border: 1px solid #abaca6;
    border-radius: 8px;
    margin-top: 0;
    padding: 0;
    height: 300px;
  }

  .el-upload-list__item-thumbnail {
    width: calc(100% - 1px);
    height: 208px;
    margin-left: 0;
    border-radius: 8px 8px 0 0;
  }
}

::v-deep .el-upload {
  width: calc(50% - 8px);
  border-radius: 8px;

  @include respond-to(mobile){
    width: 100%;
  }

  &:focus {
    border-color: $--color-primary;
    color: $--color-primary;
    & .el-upload-dragger {
      border-color: $--color-primary;
    }
  }

  &-dragger {
    width: 100%;
    height: 250px;
    height: 100%;
    border-radius: 8px;
    display: flex;
    background-color: #fff;
    border-color: get-color(primary, pure);
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .el-upload__inner {
      width: 100%;
      height: 100%;
      border-radius: 8px 8px 0px 0px;
      border: 2px solid transparent;
    }

    &:hover {
      border-color: get-color(primary, low-light);
      background: get-color(primary, high-light);

      .el-icon-upload {
        color: get-color(primary, low-medium);
      }
    }

    &.is-dragover {
      border: 2px solid get-color(primary, low-medium);

      .el-upload__inner {
        border: 2px solid #fff;
      }

      .el-icon-upload {
        color: get-color(primary, low-medium);
      }
    }

    .el-icon-upload {
      margin: 0;
      padding-bottom: 25px;
      color: get-color(primary, high-light);
    }
  }
}
</style>