import axios, { AxiosInstance } from 'axios';
import { logout } from '~/services/auth';
import showNotify from '~/utils/notificador';

class ApiAliare {
  instance:AxiosInstance

  constructor() {
    const instance = axios.create();

    // instance.interceptors.request.use((config) => {
    //   // Interceptador de solicitação
    //   if (config.headers && webStorage.getItem('tokenAliare')) {
    //     config.headers.common['Authorization'] = `Bearer ${webStorage.getItem('tokenAliare')}`;
    //   }
    //   return config;
    // }, (error) => {
    //   return Promise.reject(error);
    // });

    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        // Interceptador de resposta
        if (axios.isCancel(error)) return Promise.reject({ ...error });
        const status = error.response.status;

        switch (status) {
          case 400:
            return Promise.reject(error.response.data);
          case 401:
            logout();
            break;
          case 403:
            showNotify('error', 'Seu usuário não tem permissão para essa ação, tente realizar o login em outra conta!', 'Acesso Negado');
            return Promise.reject(error.response);
          default:
            return Promise.reject(error.response);
        }
      }
    );

    this.instance = instance;
  }

  async setBaseUrl() {
    
    const config = await fetch(process.env.BASE_URL + 'config/appsettings.json')
      .then((response) => response.json())
      .catch((error) => {
        console.error('Erro ao obter as configurações:', error);
      });
    
    if (config) {
      this.instance.defaults.baseURL = config.BASE_URL_ALIARE;
      this.instance.defaults.headers.common['Access-Control-Allow-Origin'] = config.VUE_APP_APP;
      this.instance.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,HEAD,OPTIONS,POST,PUT';
    }
  }

  getInstance() {
    return this.instance;
  }
}

const apiAliare = new ApiAliare();
apiAliare.setBaseUrl();

export default apiAliare.getInstance();
