<script>
export default {
  name: 'cpr-input',

  inheritAttrs: false,

  props: {
    required: {
      type: Boolean,
      default: false,
      required: false,
    },

    type: {
      type: String,
      default: '',
      required: false,
    },

    value: {
      type: [String, Array, Date, Number],
      required: false,
    },

    isSelect: {
      type: Boolean,
      default: false,
      required: false,
    },

    mask: {
      type: String,
      default: '',
      required: false,
    },

    selectLoading: {
      type: Boolean,
      default: false,
      required: false,
    },

    loading: {
      type: Boolean,
      default: false,
      required: false,
    },

    isDate: {
      type: Boolean,
      default: false,
      required: false,
    },

    isBetweenDate: {
      type: Boolean,
      default: false,
      required: false,
    },

    precision: {
      type: Number,
      required: false,
      default: 2,
    },

    allowNegative: {
      type: Boolean,
      default: false,
      required: false,
    },
    suffixIcon:{
      type: String,
      required: false,
      default: '',
    },
    prefixIcon:{
      type: String,
      required: false,
      default: '',
    },
    dataList: {
      type: Array,
      required: false,
      default: ()=> [],
    }
  },

  methods: {
   format(value) {
    let valorFormatado = '';
    return !value
    ? {}
    :
    {
      cnpj: () => {
        let digitoNumerico = value.replace(/\D/g, '');
        if (digitoNumerico.length > 14) {
          digitoNumerico = digitoNumerico.slice(0, 14);
        }

        const blocks = digitoNumerico.match(/^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})$/);

        valorFormatado += blocks[1] ? blocks[1] : '';
        valorFormatado += blocks[2] ? '.' + blocks[2] : '';
        valorFormatado += blocks[3] ? '.' + blocks[3] : '';
        valorFormatado += blocks[4] ? '/' + blocks[4] : '';
        valorFormatado += blocks[5] ? '-' + blocks[5] : '';

        return valorFormatado;
      },
      cpf: () => {
        let digitoNumerico = value.replace(/\D/g, '');
        if (digitoNumerico.length > 11) {
          digitoNumerico = digitoNumerico.slice(0, 11);
        }

        const blocks = digitoNumerico.match(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})$/);

        valorFormatado += blocks[1] ? blocks[1] : '';
        valorFormatado += blocks[2] ? '.' + blocks[2] : '';
        valorFormatado += blocks[3] ? '.' + blocks[3] : '';
        valorFormatado += blocks[4] ? '-' + blocks[4] : '';

        return valorFormatado;
      },
      numero: ()=>{
        let typeValue = typeof value === 'number' ? value.toFixed(this.precision) : value;

        if (typeValue.includes('-')) {
          typeValue = '-' + value.replace(/\D/g, '');
        }

        const casasDecimais = Number.parseInt('1' + '0'.repeat(this.precision));
        const digitoNumerico = typeValue.replace(this.allowNegative ? /[^0-9\-]/g : /\D/g, '');
        const conversaoNumero = parseFloat(digitoNumerico) / casasDecimais || 0;
        valorFormatado = conversaoNumero.toLocaleString('pt-BR', {
          minimumFractionDigits: this.precision,
          maximumFractionDigits: this.precision
        });

        return valorFormatado;
      },
      cep: ()=> {
        let digitoNumerico = value.replace(/\D/g, '');
        if (digitoNumerico.length > 8) {
          digitoNumerico = digitoNumerico.slice(0, 8);
        }

        const blocks = digitoNumerico.match(/^(\d{0,5})(\d{0,3})$/);

        valorFormatado += blocks[1] ? blocks[1] : '';
        valorFormatado += blocks[2] ? '-' + blocks[2] : '';

        return valorFormatado;
      },
      telefone:()=> {
        let digitoNumerico = value.replace(/\D/g, '');

        if (digitoNumerico.length > 11) {
          digitoNumerico = digitoNumerico.slice(0, 11);
        }

        const blocks = digitoNumerico.match(/^(\d{0,2})(\d{0,5})(\d{0,4})$/);

        valorFormatado += blocks[1] ? '('  + blocks[1]   : '';
        valorFormatado += blocks[2] ? ') ' + blocks[2]   : '';
        valorFormatado += blocks[3] ? '-'  + blocks[3]   : '';

        return valorFormatado;
      },
      alfanumerico:()=> {
        const digitoAlfanumerico = value.replace(/[^a-zA-Z0-9]+/g, '');
        valorFormatado = digitoAlfanumerico
        return valorFormatado
      },
    };
  },
  handlerFormat(value){
    const formatter = this.format(value);
    const valorFormatado = this.mask in formatter
    ? formatter[this.mask]()
    : value

    return valorFormatado
  },

  },

  data: () => ({
    innerValue: '',
  }),

  computed:{
    step(){
      return '0.'+ '1'.padStart(this.precision, '0')
    }
  },

  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal);
    },

    value(newVal) {
      this.innerValue = this.handlerFormat(newVal)
    },
  },

  created() {
    if (this.value != undefined || this.value != null) {
      this.innerValue = this.handlerFormat(this.value);
    }
  },
};
</script>

<template>
  <div class="input">
    <div class="input__target">
      <el-input
        v-model="innerValue"
        v-bind="$attrs"
        type="number"
        :step="step"
        v-if="type === 'number'"
      />

      <el-input
        v-else-if="!isSelect && !isDate && type !== 'numeric'"
        v-bind="$attrs"
        v-model="innerValue"
        @change="(value) => $emit('change', value)"
        :suffix-icon="`${loading ? 'el-icon-loading' : ''}`"
        :prefix-icon="`${ prefixIcon }`"
      >
        <template #suffix v-if="suffixIcon">
          <i
            :class="['el-input__icon', suffixIcon]"
            style="cursor: pointer"
            @click.prevent="$emit('click-suffix')"
          />
        </template>
      </el-input>

      <el-input-number
        v-else-if="type === 'numeric'"
        v-model="innerValue"
        :precision="precision"
        :controls="false"
        :max="100"
        @change="(value) => $emit('change', value)"
      />

      <el-select
        v-else-if="isSelect"
        v-bind="$attrs"
        v-model="innerValue"
        :loading="selectLoading"
        @input="(value) => $emit('input', value)"
        @change="(value) => $emit('change', value)"
      >
        <template #prefix v-if="prefixIcon">
          <span style="top: 14px; left: 4px; position: absolute;">
            <i :class="prefixIcon"/>
          </span>
        </template>
        <slot>             
          <el-option
            v-for="item in dataList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </slot>
      </el-select>

      <el-date-picker
        v-else-if="isDate"
        v-bind="$attrs"
        v-model="innerValue"
        :type="isBetweenDate ? 'daterange' : 'date'"
        range-separator="Até"
        format="dd/MM/yyyy"
        start-placeholder="Data inicial"
        end-placeholder="Data final"
        @change="(value) => $emit('change', value)"
      />
      <slot name="action" />
    </div>
  </div>
</template>

<style lang="scss">
input {
  width: 200px;
}

.input {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__icon {
    margin-left: 16px;
    background-color: $--color-gray-1;
  }

  &__target {
    display: flex;
    align-items: center;

    .el-input,
    .el-input-number {
      width: 100%;

      .el-input__clear {
        font-size: 16px;
        margin-right: 14px;
      }

      &__inner {
        color: $--color-gray-7;
      }

      &--suffix &__inner {
        padding-right: 48px;
        width: 100%;
      }
    }

    .el-date-editor{
      &.el-input {
        width: 100%;
      }
    }
  }

  .el-date-editor .el-range-input {
    background-color: transparent;
  }

  &.is-error .el-input {
    &__inner {
      border-color: $--color-danger;
    }

    &__suffix-inner {
      color: $--color-danger;
    }
  }
}

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;

}
input[type=number] {
 -moz-appearance: textfield;
 appearance: textfield;

}
</style>
