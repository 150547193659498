<template>
  <div class="main">
    <RouterView />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components:{
  }
})
export default class empty extends Vue {


}
</script>

<style lang="scss" scoped>
@import '../styles/mixings/_screen';

.main {
  display: flex;
  justify-content: center;
  & > :first-child {
    position: relative;
    height: 100vh;
    display: grid;
    column-gap: 16px;
    width: 100vw;
  }
  
  @include respond-to(mobile) {
    & > :first-child { 
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @include respond-to(small-tablet) {
    margin: 0 24px;
    & > :first-child { 
      grid-template-columns: repeat(8, 1fr);
    }
  }

  @include respond-to(large-tablet) {
    margin: 0 24px;
    & > :first-child { 
      grid-template-columns: repeat(12, 1fr);
    }
  }

  @include respond-to(notebook) {
    margin: 0 48px;
    & > :first-child { 
      grid-template-columns: repeat(12, 1fr);
      padding: 0 48px;
    }
  }

  @include respond-to(desktop) {
    margin: 0 72px;
    & > :first-child { 
      grid-template-columns: repeat(12, 1fr);
      padding: 0 72px;
      width: 85%;
    }
  }
}
</style>