<template>
  <div class="modal" :class="{ 'is-show': showModal }">
    <div class="modal-content" :class="`is-${ type }`">
      <div class="modal-content__header">
        <div class="icon-box">
          <cpr-icon 
            :iconName="`${configIcon[type].icon}`" 
            :color="`${configIcon[type].color}`"
            :size="25"
          />
        </div>						
        <h3>{{title || breadcrumb}}</h3>	
      </div>

      <div class="modal-content__body">
        <p class="modal-content__body-info" v-html="textInfo"></p>
      </div>
      <div class="modal-content__footer">
        <button class="btn btn-primary" @click="handlePositiveAction">{{ namePositiveAction }}</button>
        <button class="btn btn-secondary is-danger" @click="handleNegativeAction"> {{ nameNegativeAction }}</button>
      </div>
    </div>
  </div>     
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import CprIcon from '@/components/CprIcon.vue';
import { ModalFeedback } from '~/types/types';

@Component({
  name:'cpr-modal-feeback',
  components: {
    CprIcon,
  },
})
export default class CprModalFeeback extends Vue {
  @Prop({ default: false }) showModal!:boolean;
  @Prop({ default: false }) loadingModal!:boolean;
  @Prop({ default: 'warning' }) type!:ModalFeedback;  
  @Prop({default:''}) title!:string;
  @Prop({default:'Continuar'}) namePositiveAction!:string;
  @Prop({default:'Sair'}) nameNegativeAction!:string;
  @Prop({default:''}) textInfo!:string;
  @Prop({ default: ()=> ({}) }) negativeAction!:()=> Promise<void>;
  @Prop({ default: ()=> ({}) }) positiveAction!:() => Promise<void> | undefined;
  @Prop({ default: ()=> ({}) }) closeModal!:() => Promise<void>;

  get breadcrumb(){
    return this.$route?.meta?.breadcrumb ?? '';
  }

  get configIcon(){
    return {
      'warning': {
        icon: 'icon-warning',
        color: ''
      },
      'delete': {
        icon: 'icon-delete',
        color: '#DF262D'
      },
      'success': {
        icon: '',
        color: '#287027'
      }
    }
  }

  async handleNegativeAction(){
    this.closeModal();

    if(typeof this.negativeAction === 'function') await this.negativeAction();
  }

  async handlePositiveAction(){
    this.closeModal();
    if(typeof this.positiveAction === 'function') await this.positiveAction();
  }
  

}
</script>

<style lang="scss" scoped>
.btn{
  width: 100%;
  height: 40px;
}

.icon-box {
  height: 100%;
}
.modal {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 2020;
  opacity: 0;
  position: fixed;
  transition: all 0.3s ease-in-out;
  align-items: center;
  pointer-events: none;
  justify-content: center;
  background-color: rgba(get-color(neutral, pure), 0.64);
  backdrop-filter: blur(2px);
  color: get-color(fonte, text-dark);

  &.is-show {
    opacity: 1;
    pointer-events: all;
    transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1);
  }

  &-content {
    padding: 20px;
    border: none;
    text-align: center;
    font-size: 14px;
    position: relative;
    border-radius: 8px;
    background-color: get-color(neutral, ultra-light);
    width: 350px;

    &.is-warning {
      border: 2px solid get-color(alert, pure);
    }
    &.is-delete {
      border: 2px solid get-color(error, pure);

      ::v-deep .modal-content__header svg {
        & > :nth-child(2) {
          stroke: get-color(error, pure);
        }
      }
    }
    
    
    &__header {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      gap: 15px;
    }

    &__body {
      margin: 34px 0;

      &-info {
        text-align: justify;
        font-weight: 500;
      }
    }

    &__footer {
      font-size: 13px;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }

}

</style>