<template>
  <div class="modal">
    <div class="modal__container" :class="{ 'is-show': showModal }">
      <div>
        <cpr-icon :iconName="`icon-${icone}`" :size="144" :color="'#287027'" :noStroke="true"/>
      </div>
      <div class="modal-info">
        <h3>{{titulo}}</h3>
        <div v-html="mensagem || exemploMensagem" class="modal-info"></div>
      </div>
      <div class="modal-actions">
        <el-button 
          v-for="(item, index) in acoes"
          :key="'btn-'+ index"
          @click="handleAction(item.acao)"
          :class="`btn btn-${item.tipo}`"
        >
          <cpr-icon :iconName="`icon-${item.icone}`" :size="24" :noStroke="true" :classStyle="item.iconeClass"/>
          {{item.texto }}
        </el-button>
      </div>
    </div>
  </div>     
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import CprIcon from '@/components/CprIcon.vue';
import { IModalCompletionAction } from '~/types/optionsModalCompletion';

@Component({
  name:'cpr-modal-completion',
  components: {
    CprIcon,
  },
})
export default class CprModalCompletion extends Vue {
  @Prop({ default: false }) showModal!:boolean;
  @Prop({ default: 'Cadastro Realizado' }) titulo!:string;
  @Prop({ default: '' }) mensagem!:string;
  @Prop({ default: 'titulo-cadastrado'}) icone!:string;
  @Prop({ default: ()=> [
    {
      tipo: 'primary',
      texto: 'Fechar',
      acao: () => ({}),
      icone: 'return'
    } as IModalCompletionAction
  ] }) acoes!:IModalCompletionAction[];
  @Prop({ default: ()=> ({}) }) closeModal!:() => Promise<void>;

  handleAction(action: () => void) {
    this.closeModal();
    action();
  }

  /** @example */
  get exemploMensagem(){
    return `
      <p>Você poderá acompanhar a situação do título em <b>Aguardando Aprovação</b>.</p>
      <p>O usuário <b>X</b>, de e-mail <b>Y</b>, é o responsável pela aprovação deste título.</p>
      <p>Enquanto o título é avaliado pelo responsável. O que você deseja fazer agora?</p>
    `
  }

}
</script>

<style lang="scss" scoped>
.modal {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 9000;
  position: fixed;
  transition: all 0.3s ease-in-out;
  align-items: center;
  pointer-events: none;
  justify-content: center;
  background-color: #FFF;
  background-image: linear-gradient(
    to right bottom,
    rgba(135, 226, 125, 0.24),
    rgba(167, 230, 143, 0.24),
    rgba(194, 235, 163, 0.24),
    rgba(216, 240, 185, 0.24),
    rgba(234, 245, 210, 0.24),
    rgba(234, 245, 210, 0.24),
    rgba(234, 245, 210, 0.24),
    rgba(234, 245, 210, 0.24),
    rgba(216, 240, 185, 0.24),
    rgba(194, 235, 163, 0.24),
    rgba(167, 230, 143, 0.24),
    rgba(135, 226, 125, 0.24)
  );

  &__container {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    width: 364px;

    .modal-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      color: get-color(neutral, low-dark);
      text-align: center;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;

      h3 {
        color: get-color(primary, low-medium);
        font-size: 20px;
        font-weight: 600;
      }
    }

    .modal-actions {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      width: 100%;
    } 

  }
  

  & > .is-show {
    pointer-events: all;
    transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1);
  }

  & div {
    button {
      width: inherit;
      height: inherit;
    }
    .el-button + .el-button {
      margin: 0;
    }
  }
}
</style>
