<template>
  <component :is="iconName"
    :style="`height:${height || size}px; width: ${width || size}px; color: ${color}; transform: rotate(${rotate}deg) `"
    :class="[{ 'fill': fill }, { 'no-stroke': noStroke }, classStyle]" @click="$emit('click')" :color="color" />
</template>

<script>
/**@todo Manter atualizado em ordem alfabética */
/**@link https://tabler-icons.io/ */
const IconAlert = () => import('../assets/svgs/icon-alert.svg');
const IconArrow = () => import('../assets/svgs/icon-arrow-right.svg');
const IconCalendar = () => import('../assets/svgs/icon-calendar.svg');
const IconChecked = () => import('../assets/svgs/icon-checked.svg');
const IconCompany = () => import('../assets/svgs/icon-company.svg');
const IconCopy = () => import('../assets/svgs/icon-copy.svg');
const IconClipboardList = () => import('../assets/svgs/icon-clipboard-list.svg');
const IconClose = () => import('../assets/svgs/icon-close.svg');
const IconCultivation = () => import('../assets/svgs/icon-cultivation.svg');
const IconCustomerConfig = () => import('../assets/svgs/icon-customer-config.svg');
const IconDanger = () => import('../assets/svgs/icon-info.svg');
const IconDashboard = () => import('../assets/svgs/icon-dashboard.svg');
const IconDelete = () => import('../assets/svgs/icon-delete.svg');
const IconDocument = () => import('../assets/svgs/icon-document.svg');
const IconDocumentEmpty = () => import('../assets/svgs/icon-document-empty.svg');
const IconDocumentUpload = () => import('../assets/svgs/icon-document-upload.svg');
const IconDraft = () => import('../assets/svgs/icon-draft.svg');
const IconEdit = () => import('~/assets/svgs/icon-edit.svg');
const IconEllipse = () => import('../assets/svgs/icon-ellipse.svg');
const IconFarm = () => import('../assets/svgs/icon-farm.svg');
const IconFilePdf = () => import('~/assets/svgs/icon-file-pdf.svg');
const IconHarvest = () => import('../assets/svgs/icon-harvest.svg');
const IconInfo = () => import('../assets/svgs/icon-info.svg');
const IconLogout = () => import('../assets/svgs/icon-logout.svg');
const IconMailForward = () => import('../assets/svgs/icon-mail-forward.svg');
const IconManagement = () => import('../assets/svgs/icon-management.svg');
const IconPaperClip = () => import('../assets/svgs/icon-paper-clip.svg');
const IconPencil = () => import('../assets/svgs/icon-pencil.svg');
const IconPlus = () => import('../assets/svgs/icon-plus.svg');
const IconRecall = () => import('../assets/svgs/icon-recall.svg');
const IconReturn = () => import('../assets/svgs/icon-return.svg');
const IconSave = () => import('../assets/svgs/icon-save.svg');
const IconSearch = () => import('~/assets/svgs/icon-search.svg');
const IconSettings = () => import('../assets/svgs/icon-settings.svg');
const IconStore = () => import('~/assets/svgs/icon-store.svg');
const IconTemplateDocument = () => import('../assets/svgs/icon-template-document.svg');
const IconTituloCadastrado = () => import('../assets/svgs/icon-titulo-cadastrado.svg');
const IconUser = () => import('../assets/svgs/icon-user.svg');
const IconUserGroup = () => import('../assets/svgs/icon-user-group.svg');
const IconWarning = () => import('../assets/svgs/icon-warning.svg');
const IconWallet = () => import('../assets/svgs/wallet.svg');
const IconWhatsapp = () => import('../assets/svgs/icon-whatsapp.svg');
const Logo = () => import('../assets/svgs/assineiCPR_marca_assinatura_visual.svg');
const LogoAliare = () => import('../assets/svgs/assineiCPR_Aliare.svg');
const LogoMine = () => import('../assets/svgs/assineiCPR_mini_icon.svg');

export default {
  components: {
    IconAlert,
    IconArrow,
    IconCalendar,
    IconChecked,
    IconClipboardList,
    IconClose,
    IconCompany,
    IconCopy,
    IconCustomerConfig,
    IconCultivation,
    IconDanger,
    IconDashboard,
    IconDelete,
    IconDocument,
    IconDocumentEmpty,
    IconDocumentUpload,
    IconDraft,
    IconEdit,
    IconEllipse,
    IconFarm,
    IconFilePdf,
    IconHarvest,
    IconInfo,
    IconLogout,
    IconMailForward,
    IconManagement,
    IconPaperClip,
    IconPencil,
    IconPlus,
    IconRecall,
    IconReturn,
    IconSave,
    IconSearch,
    IconSettings,
    IconStore,
    IconTemplateDocument,
    IconTituloCadastrado,
    IconUser,
    IconUserGroup,
    IconWarning,
    IconWallet,
    IconWhatsapp,
    Logo,
    LogoAliare,
    LogoMine
  },
  props: {
    iconName: {
      type: String,
      required: true
    },
    fill: {
      type: Boolean,
      default: false
    },
    noStroke: {
      type: Boolean,
      default: false
    },
    classStyle: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: null
    },
    width: {
      type: Number,
      default: null
    },
    rotate: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: 'currentColor'
    },
  }
};
</script>

<style lang="scss" scoped>
svg.fill {
  ::v-deep g {
    fill: currentColor;
  }

  ::v-deep path {
    fill: currentColor;
  }
}

svg.no-fill_stroke {
  ::v-deep path {
    fill: none !important;
    stroke: currentColor !important;
  }
}

svg.no-stroke {
  ::v-deep g {
    stroke: none;
  }

  ::v-deep path {
    stroke: none;
  }
}

svg {
  ::v-deep g {
    stroke: currentColor;
  }

  ::v-deep path {
    stroke: currentColor;
  }

}
</style>
