<template>
  <cpr-modal
    :show="showModal"
    :closeFunction="handleModalForm"
    :outsideClick="false"
    isFooter
    :closeButton="false"
    :title="title"
    :loading="$attrs.loading"
    v-bind="$attrs"
  >
    <template #head>
      <div class="form__head-back" @click.prevent="handleModalForm">
        <cpr-icon iconName="icon-arrow" :rotate="180"/>
        <span>{{ nameBack || breadcrumb }}</span>
      </div>
    </template>
    <template #body>
      <el-form
        :ref="refForm"
        :model="formModel"
        :status-icon="iconCheck"
        :disabled="disabled"
        class="form"
      >
        <slot></slot>
      </el-form>
    </template>
    <template #footer>
      <el-button class="btn btn-secondary" @click.prevent="handleModalForm">
        {{ nameCancel }}
      </el-button>
      <el-button class="btn btn-primary" @click.prevent="validateForm(submit)" :disabled="disabledSubmit" :loading="loadingSubmit">
        {{ nameSubmit }}
      </el-button>
    </template>
  </cpr-modal>
</template>

<script lang="ts">
import {  Component, Prop, Mixins } from 'vue-property-decorator';
import CprModal from './CprModal.vue';
import CprIcon from './CprIcon.vue';
import CprFormValidate from '~/mixins/validate-form';

@Component({
  components: {
    'cpr-modal': CprModal,
    'cpr-icon': CprIcon,
  },
})
export default class CprModalForm extends Mixins(CprFormValidate) {
  @Prop({ required: false }) nameBack!: string;
  @Prop({ default: 'Voltar', required: false }) title!: string;
  @Prop({ default: false, required: true }) showModal!: boolean;
  @Prop({ default: false }) disabledSubmit!: boolean;
  @Prop({ default: false }) loadingSubmit!:boolean;

  get breadcrumb(){
    return this.$route?.meta?.breadcrumb ?? ''
  }

  handleModalForm() {
    this.validateBack
      ? this.validateForm(this.cancel as () => Promise<void>)
      : this.resetForm();
  }
}
</script>
  
<style lang="scss" scoped>
@import '../styles/mixings/_screen';

::v-deep .modal__wrapper {
  background-color: get-color(neutral, high-light);

  &-head, &-footer {
    background-color: get-color(neutral, ultra-light);
  }
  
  &-footer {
    justify-content:space-between;

    & > button {
      width: auto;
      min-width: 110px;
    }
    
    @include respond-to(mobile){
      & > button {
        width: 100%;
      }
    }
  }
}

.form {
  width: 100%;
  
  &__head-back {
    display: flex;
    align-items: center;
    color: $--color-primary;
    color: get-color(primary, low-light);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    gap: 10px;
    cursor: pointer;

    ::v-deep {
      svg {
        path {
          fill: get-color(primary, low-light);
          stroke: get-color(primary, low-light);
        }
      }
    }
  }
}

</style>
  