import store from '~/store';
import Empty from '~/layouts/empty.vue';
import modeloMinuta from './modelo-minuta/index.routes'

export default {
  path: '/gerenciar',
  component: Empty,
  children: [
    {
      path: '',
      component: () => import('./index.vue'),
      name: 'GerenciarContasRecursos',
      meta: {
        requiresAuth: true,
        routeAdmin: true,
        breadcrumb: 'Gerenciar Contas e Recursos'
      },
    },
    modeloMinuta
  ],
  beforeEnter: (to, from, next) => {
    const empresaAdmin = store.getters['auth/accessEmpresaAdmin'];
    if (empresaAdmin) next();
    else next({ name: from.name || 'Dashboard' }); 
  },
};
