const hasValidLength = (cpf) => {
  return cpf.length === 11
}

const hasAllDifferentDigits = (cpf) =>{
  return !/^([0-9])\1+$/.test(cpf)
}

const calculateDV1 = (cpf: string): number => {
  const digits =  cpf.split('').map(digit => parseInt(digit))
  const soma = (10 * digits[0] + 9 * digits[1] + 8 * digits[2] + 7 * 
    digits[3] + 6 * digits[4] + 5 * digits[5] + 4 * digits[6] + 3 * 
    digits[7] + 2 * digits[8]
  )
  return (soma % 11) < 2 ? 0 : 11 - (soma % 11)
}

const calculateDV2 = (cpf: string, dv1: number): number => {
  const digits =  cpf.split('').map(digit => parseInt(digit))
  const soma = (11 * digits[0] + 10 * digits[1] + 9 * digits[2] + 8 * 
    digits[3] + 7 * digits[4] + 6 * digits[5] + 5 * digits[6] + 4 * 
    digits[7] + 3 * digits[8] + 2 * dv1
  )
  return (soma % 11) < 2 ? 0 : 11 - (soma % 11)
}

const hasValidDVs = (cpf: string, dv1: number, dv2: number): boolean => {
  return dv1 === parseInt(cpf[9]) && dv2 === parseInt(cpf[10])
}

export const validateCPF = (cpf: string): boolean => {
  // Remover pontos e hífen do CPF
  cpf = cpf.replace(/\./g, '').replace(/-/g, '')

  // Verificar se o CPF tem 11 dígitos e todos eles são diferentes de zero
  if (!hasValidLength(cpf) || !hasAllDifferentDigits(cpf)) {
    return false
  }

  // Aplicar fórmulas para calcular os dígitos verificadores
  const dv1 = calculateDV1(cpf)
  const dv2 = calculateDV2(cpf, dv1)

  // Verificar se os dígitos verificadores são iguais aos do CPF informado
  if (!hasValidDVs(cpf, dv1, dv2)) {
    return false
  } 

  return true;
}
