<template>
  <div id="#app">
    <transition name="fade--app" mode="out-in" @before-leave="onBeforeLeave" @after-enter="onAfterEnter">
      <router-view key="app" />
    </transition>
  </div>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';


@Component({
  name: 'App',
  components: {
  },
})
export default class App extends Vue {

  created () {
    this.onBeforeLeave()
  }

  mounted () {
    this.onAfterEnter()
    if (window.location.hostname !== 'localhost') {
      this.clarityInitialize()
    }
  }

  clarityInitialize() {
    (function(c: any, l: Document, a: string, r: string, i: string) {
      c[a] = c[a] || function(...args: any[]) { (c[a].q = c[a].q || []).push(args); };
      const t: HTMLScriptElement = l.createElement(r) as HTMLScriptElement; 
      t.async = true;
      t.src = 'https://www.clarity.ms/tag/' + i;
      const y: HTMLElement | null = l.getElementsByTagName(r)[0] as HTMLElement | null;
      if (y && y.parentNode) {
        y.parentNode.insertBefore(t, y);
      }
    })(window, document, 'clarity', 'script', 'n5vwqmmjh9');
  }

  
  onAfterEnter () {
    document.body.style.overflow = ''
  }

  onBeforeLeave () {
    document.body.style.overflow = 'hidden'
  }


}
</script>

<style lang="scss" scoped>
.fade--app-enter-active, .fade--app-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.fade--app-enter, .fade--app-leave-to {
  opacity: 0;
  overflow: hidden;
  transform: translateX(18px); /* Deslocamento horizontal durante a transição */
}
</style>