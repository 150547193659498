<template>
  <div class="cpr-radio fill-grid">
    <label>{{ label }}
      <el-tooltip v-if="info" effect="dark" :content="info" placement="top">
        <i class="el-icon-info" style="margin-left: 12px;"/>
      </el-tooltip>
    </label>
    <el-radio-group v-model="valor" v-bind="$attrs" @change="(v)=> $emit('change', v)">
      <template v-if="data">
        <el-radio
          v-for="(d, index) in data"
          :key="`${(d.prop || d.value || d)}` + index"
          :label="obterLabel(d, index)"
          :disabled="d.disabled"
        >
          {{ d.label || d }}
        </el-radio>
      </template>
      <slot/>
    </el-radio-group>
  </div>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from 'vue-property-decorator';
import type { ILabel } from '~/types/label';


@Component({
  name: 'cpr-radio-button'
})
export default class CprRadioButton extends Vue {
  @VModel() valor;
  @Prop() data!:ILabel[];
  @Prop() label!:string;
  @Prop() info!:string;

  obterLabel(label: ILabel, index: number,){
    return (label?.prop ?? label?.value ?? index)
  }

}
</script>

<style lang="scss" scoped>
@import '../../styles/mixings/_screen';
@import '../../styles/mixings/_responsive-grid';

.cpr-radio {
  color: get-color(fonte, text-medium);
  margin-bottom: 20px;

  > label {
    font-size: 14px;
  }
}

::v-deep .el-radio__input.is-disabled.is-checked .el-radio__inner::after {
  background-color: #287027;
}

 ::v-deep .el-radio__input.is-disabled.is-checked + span.el-radio__label {
  color: #287027;
  cursor: pointer;
}

.el-radio-group {
  line-height: 1;
  vertical-align: middle;
  row-gap: 15px;
  margin: 10px 0;

  @include responsive-grid;

  .el-radio__input.is-checked .el-radio__inner {
    background: get-color(neutral, ultra-light);
  }

}
::v-deep .el-radio {
  border: 1px solid get-color(neutral, pure);
  background: get-color(neutral, ultra-light);
  padding: 12px 16px;
  gap: 5px;
  height: 48px;
  border-radius: 8px;
  margin-right: 0;
  display: flex;
  align-items: center;

  &.is-checked {
    border-color: get-color(primary, pure);
    background: get-color(primary, ultra-light);
    color: get-color(primary, low-medium);
  }

  &__input {
    &.is-disabled {
      + span.el-radio__label {
        color: get-color(neutral, pure);
      }
      &.is-checked {
        + span.el-radio__label {
          color: get-color(primary, low-medium);
        }

        .el-radio__inner {
          border-color: get-color(primary, low-medium);

          &::after {
            background-color: get-color(primary, low-medium);
          
          }
        }
      }

      &:not(.is-checked) {

        .el-radio__inner {
          border-color: get-color(neutral, pure);
          background: get-color(neutral, high-medium);
        }
      }
    }
  }

  &.is-disabled:not(.is-checked) {
    background: get-color(neutral, high-medium);
    border: none;
    cursor: not-allowed;
  }

  &__inner {
    border: 1px solid get-color(neutral, pure);
    border-radius: 100%;
    width: 20px;
    height: 20px;
    background-color: $--color-gray-1;
    position: relative;
    cursor: pointer;
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    &:hover {
      border-color: get-color(primary, low-medium);
    }
    &::after {
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: get-color(primary, low-medium);
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%) scale(0);
      transform: translate(-50%, -50%) scale(0);
      -webkit-transition: -webkit-transform .15s ease-in;
      transition: -webkit-transform .15s ease-in;
      transition: transform .15s ease-in;
      transition: transform .15s ease-in, -webkit-transform .15s ease-in;
    }
  }
}

</style>
