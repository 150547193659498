import axios from 'axios';
const baseURL = '/safra';

export const save = async (harvest, harvestId, companyId) => {
  if (harvestId) {
    return axios.put(`${baseURL}/${harvestId}`, harvest).then((res) => res.data);
  }
  const newHarvest = { ...harvest, EmpresaId: companyId };
  return axios.post(baseURL, newHarvest).then((res) => res.data);
};

export const getById = (id) => axios.get(`${baseURL}/${id}`).then((res) => res.data[0]);
export const getAll = () => axios.get(baseURL).then((res) => res.data);
export const deleteById = (id) => axios.delete(`${baseURL}/${id}`).then((res) => res.data);
