import baseService from '../baseServise';
import type { IEndossatarioModel } from '~/models/endossatario';

class ServiceEndossatario extends baseService<IEndossatarioModel> {
  constructor() {
    super('Endossatario')
  }

}

export default new ServiceEndossatario();