<template>
  <el-form-item :prop="prop" :label-suffix="prop" :rules="$validationForm(rules)">
    <template #label> 
      {{label}}
      <el-tooltip v-if="info" effect="dark" :content="info" placement="top">
        <i class="el-icon-info" style="margin-left: 12px;"/>
      </el-tooltip>
    </template>

    <cpr-input
      v-model="valor"
      v-bind="$attrs"
      @change="(v) => $emit('change', v)"
      :placeholder="placeholder"
      :id="prop"
      :loading="loading"
      :prefixIcon="prefixIcon"
      :suffixIcon="suffixIcon"
    >
    <slot/>
    </cpr-input>
  </el-form-item>
</template>

<script lang="ts">
import { Vue, Component, Prop, VModel } from 'vue-property-decorator';
import CprInput from '../Input.vue';
import { ValidationRule } from '~/utils/validationForm';

/** @todo utilizar esse componente para inputs de formulário */
@Component({
  name:'cpr-form-input',
  components: {
    'cpr-input': CprInput,
  },
})
export default class CprFormInput extends Vue {
  @Prop({default:''}) label!:string;
  @Prop({default:'', required: true}) prop!:string;
  @Prop({default:''}) prefixIcon!:string;
  @Prop({default:''}) suffixIcon!:string;
  @Prop({default:''}) info!:string;
  @Prop({default: false }) loading!:boolean;
  @Prop({default:''}) placeholder!:string;
  /** @see ValidationRule */
  @Prop({default:()=>[]}) rules!:ValidationRule[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @VModel() valor!:any;

}
</script>

<style lang="scss">
/** @todo retirar ao padronizar as cores da plataforma */
.el-select {
  &-dropdown  {
    background-color: get-color(neutral, ultra-light);

    &__item {
      color: get-color(neutral, low-dark);

      &.selected {
        color: get-color(primary, low-medium);
        background-color: get-color(primary, high-light);

      }

      &:hover{
        background-color: get-color(neutral, high-light);
      }
    }
  }
}

.el-tooltip {
  position: relative;
  z-index: 2;
  max-width: 350px;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  color: get-color(primary, low-medium);
  background-color: get-color(primary, high-light);
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected.hover {
  background-color: get-color(neutral, high-light);
}

.el-tag {
  border-radius: 8px;

  &.el-tag--info {
    background-color: get-color(primary, high-light);
    border: none;
    font-size: 13px;
    font-weight: 600;
    color: get-color(primary, low-medium);

    .el-tag__close{
      color: get-color(primary, low-light);
      font-size: 14px;
      font-weight: 600;
      background-color: transparent;

      &:hover {
        color: #FFF;
        background-color: get-color(primary, low-medium);
      }
    }
  }
}

.el-input {
  &__inner{
    background-color: get-color(neutral, ultra-light);
    border: 1px solid get-color(neutral);

    &:focus {
      outline: none;
      border-color: get-color(primary, low-light);
    }
  }

  &.is-disabled {
    .el-input__inner {
      background-color:get-color(neutral, high-medium) ;
    }
  }
}

.input__target .el-input__inner, .input__target .el-input-number__inner {
  color: get-color(neutral, low-dark) !important;
}

.el-select:hover .el-input__inner,
.el-range-editor.is-active, 
.el-range-editor.is-active:hover, 
.el-select .el-input.is-focus .el-input__inner,
.el-select .el-input__inner:focus {
  border: 1.5px solid get-color(neutral, low-medium);
}

@media screen and (max-width: 420px) {
  .el-select-dropdown__item {
    max-width: 98vw;
  }
}
</style>