import Empty from '~/layouts/empty.vue';

export default {
  path: '/aprovacao',
  component: Empty,
  children: [
    {
      path: '',
      component: () => import('~/views/App/Aprovacao/index.vue'),
      name: 'Aprovacao',
      meta: {
        requiresAuth: true,
        breadcrumb: 'Aprovação'
      },
    }
  ],
};
