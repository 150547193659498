import axios from 'axios';
import { ICPRFVcpModel } from '~/models/cprFinanceira';
import type {IB3SafraPhsModel, ICarteiraModel,IRegistroCercModel} from '~/models/docRegistrationHub';
import type { IRegistroB3, IDocRegistrationLogErro } from '~/models/docRegistrationHub';
import type { IApiOKResult } from '~/types/api';

class ServiceDocRegistration {
  private readonly baseUrl: string;

  constructor() {
    this.baseUrl = 'DocRegistrationHub';
  }

  async obterCarteiras(tenantId: string) {
    return (await axios.get<IApiOKResult<ICarteiraModel[]>>(`${this.baseUrl}/obter-carteiras?tenantId=${tenantId}`)).data.data;
  }

  async solicitarRegistro(payload: IRegistroCercModel) {
    return await axios.put(`${this.baseUrl}/solicitar-registro`, payload);
  }

  async atualizaStatus(tituloId:string) {
    return (await axios.put(`${this.baseUrl}/update-status-registro-cpr/${tituloId}`)).data;
  }

  async credenciarCredor(payload: IRegistroB3) {
    return await axios.put(`${this.baseUrl}/credenciar-credor`, payload);
  }
  
  async atualizarCredorContas(payload: IRegistroB3) {
    return await axios.put(`${this.baseUrl}/atualizar-credor`, payload);  
  }

  async getCredicaisCredor(tenantId: string){      
    return (await axios.get<IApiOKResult<IRegistroB3>>(`${this.baseUrl}/credenciais/credor/${tenantId}`)).data;
  }

  async getListLogsDocRegistration(params: { tituloId: number }){      
    return (await axios.get<IApiOKResult<IDocRegistrationLogErro[]>>(`${this.baseUrl}/logs/errors`, { params })).data;
  }

  /** 
   * @param safra Descrição, sem a barra (20222023)
   * @param produto Descrição (milho)
  */
  async buscarB3SafraECulturasPhs(safra:string, produto:string){      
    return (await axios.get<IApiOKResult<IB3SafraPhsModel>>(`${this.baseUrl}/publicInfo/${safra}/${produto}`)).data;
  }
  
  async obterListaPrincipaisIndexadoresVCP() { 
    return axios.get<IApiOKResult<ICPRFVcpModel[]>>(`${this.baseUrl}/VCP`)
      .then(({data}) => data
    );
  }
  
}

export default new ServiceDocRegistration();
