import axios from 'axios';
import {IApiOKResult} from '~/types/api'

/** 
 *  @extends T Command interface
 *  @extends K Query interface
 */
export default abstract class baseService<T, K = T> {
  protected baseURL: string;
  constructor(baseURL:string){
    this.baseURL = baseURL;
  }
  async getById (id:number | string): Promise<IApiOKResult<K>> { 
    return axios.get(`${this.baseURL}/${id}`).then(({data}) => data);
  }

  async deleteById(id:number | string) {
    return axios.delete(`${this.baseURL}/${id}`).then(({data}) => data);
  } 
  
  async getAll (): Promise<IApiOKResult<Array<K>>> {
    return  axios.get(`${this.baseURL}`).then(({data}) => data);
  } 

  async save (model:T, id?:string | number) {
    if (id) {
      return axios.put(`${this.baseURL}/${id}`, model).then(({data}) => data);
    }
    return axios.post(`${this.baseURL}`, model).then(({data}) => data);
  }

  async addOrUpdate (model:T) {
    return axios.put<IApiOKResult<T>>(`${this.baseURL}`, model).then(({ data }) => data);
  }

  async addRange (model:Array<T>) {
    return axios.post(`${this.baseURL}/AddRange`, model).then((res) => res.data);
  }

  async updateRange (model:Array<T>) {
    return axios.put(`${this.baseURL}/UpdateRange`, model).then((res) => res.data);
  }

}
