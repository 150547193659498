export const validarCNPJ = (cnpj: string): boolean => {
  // Remover caracteres não numéricos
  cnpj = cnpj.replace(/\D/g, '');

  // CNPJ deve ter 14 dígitos após a remoção dos caracteres não numéricos
  if (cnpj.length !== 14) {
    return false;
  }

  // Verificar se todos os dígitos são iguais
  const digitosIguais = /(.)\1{13}/.test(cnpj);
  if (digitosIguais) {
    return false;
  }

  // Validar o primeiro dígito verificador
  let soma = 0;
  for (let i = 0; i < 12; i++) {
    soma += parseInt(cnpj.charAt(i)) * (i < 4 ? 5 - i : 13 - i);
  }
  let resto = soma % 11;
  const primeiroDigitoVerificador = resto < 2 ? 0 : 11 - resto;
  if (primeiroDigitoVerificador !== parseInt(cnpj.charAt(12))) {
    return false;
  }

  // Validar o segundo dígito verificador
  soma = 0;
  for (let i = 0; i < 13; i++) {
    soma += parseInt(cnpj.charAt(i)) * (i < 5 ? 6 - i : 14 - i);
  }
  resto = soma % 11;
  const segundoDigitoVerificador = resto < 2 ? 0 : 11 - resto;
  if (segundoDigitoVerificador !== parseInt(cnpj.charAt(13))) {
    return false;
  }

  // CNPJ válido
  return true;
}
